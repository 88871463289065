import React from 'react';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/react-hooks';
import ButtonContainer from '../common/buttonContainer';
import Button from '../common/button';

const UPDATE_IS_PUBLISH = gql`
  mutation updateIsPublish($trialId: String!, $clientId: String!, $isPublish: Boolean!) {
    updateIsPublish(trialId: $trialId, clientId: $clientId, isPublish: $isPublish) {
      isPublish
    }
  }
`;

export default function FinalizeTrial({trial, isPublishChanged}) {
  const [updateIsPublish] = useMutation(UPDATE_IS_PUBLISH);
  const onClick = () => {
    updateIsPublish({variables: {trialId: trial._id, clientId: trial.client._id, isPublish: !trial.isPublish}})
      .then(() => isPublishChanged());
  }

  if (trial.herdingEvents && trial.herdingEvents.length > 0) {
    return (
      <ButtonContainer>
        <Button minWidth={160} onClick={onClick}>{trial.isPublish ? 'Unpublish Trial' : 'Publish Trial'}</Button>
      </ButtonContainer>
    );
  }

  return null;
}

FinalizeTrial.propTypes = {
  trial: PropTypes.object.isRequired,
  isPublishChanged: PropTypes.func.isRequired
}
