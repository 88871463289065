import React from 'react';
import theme from '../../lib/common/theme';
import styled from '@emotion/styled';


export default function PageHeader({ children }) {
  const PageHeader = styled.h1`
    color: ${theme.secondaryTextHighlight};
    margin-left: ${theme.medium};
  `;

  return (
    <PageHeader>{children}</PageHeader>
  );
}

