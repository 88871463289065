import React from 'react';
import styled from '@emotion/styled';

export default function SubTitle({children}) {
  const DesktopText = styled.span`
    display: none;
    @media (min-width: 500px) {
      display: inline-block;
    }
  `;

  return (
    <DesktopText>{children}</DesktopText>
  );
}