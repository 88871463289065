import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from "react-router-dom";

export default function IfAuth({ clientId, children }) {
  if (clientId) {
    return (children);
  }

  return (<Redirect to="/" />);
}

IfAuth.propTypes = {
  clientId: PropTypes.string
}