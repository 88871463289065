import React from 'react';
import theme from '../../lib/common/theme';
import styled from '@emotion/styled';


export default function Paragraph({ children }) {
  const Paragraph = styled.p`
    color: ${theme.primaryText};
  `;

  return (
    <Paragraph>{children}</Paragraph>
  );
}

