import React from 'react';
import PropTypes from 'prop-types';
import Paragraph from './common/paragraph';
import styled from '@emotion/styled';
import theme from '../lib/common/theme';

export default function Faq({ question, answer }) {
  const Question = styled.h3`
    color: ${theme.primaryTextHighlight1};
  `;

  return (
    <div>
      <Question>{question}</Question>
      <Paragraph>{answer}</Paragraph>
    </div>
  )
}

Faq.propTypes = {
  question: PropTypes.string.isRequired,
  answer: PropTypes.string.isRequired
}