import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import theme from '../../lib/common/theme';


const InputTag = styled.input`
  font-size: ${theme.medium};
  background-color: ${theme.primaryBackground};
  border: solid 1px ${theme.primaryTextHighlight2};
  color: ${theme.primaryText};
  width: 100%;
  padding-left: ${theme.xsmall};
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;

  &:focus {
    border-color: ${theme.primaryText};
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, .25);
  }
`;

const DisableInputTag = styled.input`
  font-size: ${theme.medium};
  background-color: ${theme.secondaryBackground};
  border: solid 1px ${theme.primaryTextHighlight2};
  color: ${theme.primaryText};
  opacity: 0.5;
  width: 100%;
  padding-left: ${theme.xsmall};
  cursor: not-allowed;
`;

export default function Input({id, name, type, value, onChange, disable}) {

  if (disable) {
    return (
      <DisableInputTag id={id} name={name} type={type} value={value} onChange={onChange} disabled />
    );
  }

  return (
    <InputTag id={id} name={name} type={type} value={value} onChange={onChange} />
  );
}

Input.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  disable: PropTypes.bool
}