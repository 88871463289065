import React from 'react';
import Faq from '../components/faq';
import PageHeader from '../components/common/pageHeader';

export default function Faqs() {
  
  return (
    <div>
      <PageHeader>FAQ's</PageHeader>
      <Faq 
        question='Why did you create a site for listing herding and agility trails?'
        answer='My wife, who competes in herding trials, felt that the community was lacking resources for both
        herding and agility trial secretaries. Since I have completed most work on the Foy Trent Dog
        Show’s online entry system, I could dedicate time to creating this resource for the herding and
        agility communities.' />

      <Faq 
        question='How are you making money on this site?'
        answer="By using Amazon Web Services' (AWS) free tier, I can provide a website to the AKC herding
        community at no charge. Plans are in the works to add the ability for a user to enter events
        online, at which time I will charge a nominal fee per entry." />

      <Faq
        question='Are you selling or tracking my data?'
        answer="No. I will never sell the user’s data or show ads on this website." />

      <Faq
        question='I noticed a login button, do I have to log in to use the website?'
        answer='Only registered Trial Secretaries need to login to the website. The ability to register online will
        be a future feature. When this feature is complete, it will only require a user to login if they
        register for an event.' />

      <Faq
        question="Once I'm registered as a Trial Secretary, how many events can I list on your website for free?"
        answer="There will be no limit as to the number of events I will allow a Trial Secretary to post to this
        website. I'm hoping the Dog Community finds this website useful and uses it to list all upcoming
        herding and agility trials." />

      <Faq
        question="Once the ability to register online is complete, what will the fee be?"
        answer="Once the ability to register online is functional, there will be a very nominal fee. I want to keep
        the fee small so that this becomes a primary resource for the herding and agility communities." />

      <Faq
        question="Once the ability to register online is available, how much will it cost to list a trial on your site?"
        answer="Nothing. This site will always be free for Trial Secretaries to post their upcoming trials." />

      <Faq
        question="I noticed this website looks like Foy Trent’s website
        (https://foytrentdogshows.meteorapp.com/) did you do that website too?"
        answer="Yes. I have worked with Foy Trent over the past three years to create his online entry system. It
        is my goal to make my services available to other show superintendents soon." />

      <Faq
        question="I'm a Superintendent, do you make websites for other superintendents too?"
        answer="That is the plan. I can take what I did for Foy Trent and change it to fit a different
        Superintendent. There are actually two websites that I created for Foy Trent: 1) A public-facing
        site for the public to register for dog shows, and 2) an administrative site for the superintendents
        to perform tasks specific to creating and running a show. The administrative website includes
        functions for reporting, manually keying in registrations, pushing results to AKC, and more." />

      <Faq
        question="How much do you charge for a Superintendent website?"
        answer="There is no upfront cost other than $100 - $200 per month plus $0.25 per entry. This includes
        one dedicated public website and one dedicated administrative website. Off-peak times server
        costs run around $100 per month because only two servers are needed. During peak times, server
        costs can run around $200 per month. During peak times, I increase the number of servers to
        accommodate more users at once. I also increase the number of CPU’s for the administrative site
        to handle many admin users interacting with the admin site. These are the reasons for the
        increase in server costs." />

      <Faq
        question="Who can I contact with questions, thoughts, or concerns about this website?"
        answer="Please email all questions, comments, and or concerns to gigaflops.io@gmail.com." />
    </div>
  );
}