import React from 'react';
import styled from '@emotion/styled';
import theme from '../../lib/common/theme';
import PropTypes from 'prop-types';

export default function TableCell({children, textAlign}) {
  const TableCell = styled.td`
    font-size: ${theme.medium};
    color: ${theme.primaryTextHighlight1};
    text-align: ${textAlign || 'center'};
    max-width: 100px;
    overflow-x: hidden;
  `;

  let title = typeof children === 'string' ? children : '';

  return (
    <TableCell title={title}>{children}</TableCell>
  );
}

TableCell.propTypes = {
  textAlign: PropTypes.string
}