import React from 'react';
import PropTypes from 'prop-types';
import Auth from './security/auth';
import styled from '@emotion/styled';
import theme from '../lib/common/theme';
import Logo from './common/logo';


export default function Header({ onLogin, onLogout, currentUser }) {
  const Header = styled.header`
    display: flex;
    justify-content: space-between;
    background-color: ${theme.primaryBackground};
    line-height: ${theme.headerHeight};
    border-bottom: ${theme.xsmall} solid ${theme.barrierColor};
  `;

  const TitleContainer = styled.div`
    display: flex;
  `;

  return (
    <Header>
      <TitleContainer>
        <Logo />
      </TitleContainer>

      <Auth 
        onLogin={onLogin} 
        onLogout={onLogout}
        currentUser={currentUser}
        />
    </Header>
  );
}

Header.propTypes = {
  onLogin: PropTypes.func.isRequired,
  onLogout: PropTypes.func.isRequired,
  currentUser: PropTypes.object
}