import React from 'react';
import PropTypes from 'prop-types';
import theme from '../../lib/common/theme';
import styled from '@emotion/styled';

export default function Logo({oneColor}) {
  const Logo = styled.svg`
    padding: ${theme.small};
    margin-left: ${theme.small};
    width: 125px;
    height: 51px;
  `;

  return (
    <Logo>
      <g>
        <g>
          <g>
            <path fill={oneColor || theme.primaryText} d="M67.021,49.713c1.209,0,2.209-0.051,2.998-0.152c0.789-0.101,1.401-0.377,1.838-0.832
              c0.438-0.453,0.738-1.149,0.908-2.09c0.168-0.938,0.25-2.233,0.25-3.879V25.579c0-1.109-0.074-2.006-0.227-2.696
              c-0.15-0.688-0.428-1.234-0.83-1.637c-0.404-0.403-0.957-0.68-1.663-0.832c-0.705-0.151-1.63-0.227-2.771-0.227V19.18
              c3.86-0.404,7.574-1.311,11.134-2.721v26.3c0,1.646,0.085,2.939,0.253,3.88c0.168,0.939,0.453,1.637,0.855,2.09
              c0.402,0.455,0.965,0.729,1.688,0.832c0.722,0.102,1.638,0.151,2.745,0.151v1.008c-1.444-0.067-2.88-0.118-4.308-0.151
              c-1.429-0.033-2.848-0.051-4.258-0.051c-1.445,0-2.881,0.018-4.309,0.051s-2.863,0.084-4.307,0.151v-1.007H67.021z"/>
          </g>
          <g>
            <path fill={theme.secondaryTextHighlight} d="M98.713,1.958c1.708,0.666,0.737,4.012,2.508,4.618c0.547-0.09,1.157-0.708,1.848-1.188
              c0.6-0.418,1.186-1.071,2.242-0.924c0.639,2.052-0.504,4.494-0.66,6.992c-0.199,3.207,0.053,6.355-1.188,8.575
              c0.586,4.041,3.729,5.79,6.067,8.443c1.722,1.949,3.27,3.775,4.354,6.201c0.915-0.094,1.267-1.34,2.244-1.584
              c2.351-0.588,5.392,0.889,6.858,2.77c2.957,3.785,1.479,10.919-3.561,11.611c-1.644,0.228-3.109-0.104-4.486,0
              c-2.069,0.156-3.375,1.037-5.41,1.715c-0.389,0.693-0.78,1.287-1.846,1.451c-3.267,0.498-9.356-0.531-13.192-0.396
              c-1.904-2.181,0.841-3.854,3.562-3.431c-0.729-1.543-2.584-2.303-2.771-4.354c-0.114-1.266,0.745-2.273-0.265-3.166
              c-0.389,1.719-0.701,4.113-1.451,6.332c-0.588,1.74-1.572,4.363-2.901,4.883c-1.06,0.41-3.708,0.289-4.749-0.396
              c-0.468-1.971,1.001-3.006,2.902-3.034c1.33-4.834,1.486-13.272-0.528-17.414c-0.799-0.376-1.623-0.619-2.242-1.188
              c-1.879-1.726-2.127-6.015-3.825-7.388c-0.457-0.37-1.217-0.439-1.716-0.792c-1.373-0.97-2.622-3.045-1.188-4.221
              c1.002-0.13,1.97,0.479,2.902,0.792c1.068,0.357,2.252,0.478,3.034,0.923c1.251,0.712,1.731,2.025,2.507,3.166
              c0.625-1.005,0.271-1.831,0.396-3.034c0.129-1.257,1.188-2.506,1.056-3.694c-0.149-1.36-2.075-1.951-2.375-3.43
              c-0.084-0.415,0.128-0.837,0.132-1.319c0.018-1.654-0.187-2.769,1.057-3.826c1.867-0.057,2.897,1.172,4.354,1.055
              c1.462-0.118,2.535-1.974,4.617-1.452C97.512,4.1,97.688,2.604,98.713,1.958z M114.545,42.725c0.865-0.117,2.641-2,2.374-3.826
              c-0.137-0.946-1.088-2.178-2.242-1.584C115.01,38.969,114.745,41.166,114.545,42.725z"/>
          </g>
          <g>
            <path fill={theme.secondaryTextHighlight} d="M0.553,33.768c0.633-0.053,1.135-0.131,1.504-0.235c0.369-0.105,0.646-0.273,0.832-0.502
              c0.185-0.229,0.304-0.533,0.356-0.91c0.053-0.379,0.08-0.867,0.08-1.465v-9.898c0-0.598-0.026-1.086-0.08-1.464
              c-0.052-0.378-0.171-0.682-0.356-0.911c-0.185-0.228-0.467-0.396-0.845-0.501C1.665,17.776,1.15,17.696,0.5,17.644V17.01
              c0.563,0.053,1.122,0.106,1.676,0.159c0.554,0.053,1.113,0.079,1.676,0.079c1.09,0,2.226-0.044,3.405-0.132
              c1.179-0.088,2.578-0.131,4.196-0.131c1.196,0,2.389,0.197,3.577,0.593s2.248,0.991,3.181,1.783
              c0.932,0.791,1.689,1.772,2.27,2.942c0.581,1.171,0.871,2.53,0.871,4.079c0,1.425-0.25,2.657-0.752,3.696
              c-0.502,1.037-1.179,1.891-2.033,2.559c-0.854,0.67-1.839,1.166-2.956,1.492c-1.118,0.324-2.301,0.488-3.55,0.488
              c-0.722,0-1.346-0.01-1.874-0.027s-1.052-0.039-1.571-0.064c-0.519-0.026-1.069-0.053-1.649-0.08
              c-0.58-0.026-1.285-0.038-2.111-0.038c-0.933,0-1.742,0.008-2.429,0.024c-0.686,0.019-1.311,0.045-1.874,0.08V33.768z
              M6.861,32.344c0,0.246,0.105,0.453,0.316,0.619c0.211,0.168,0.493,0.303,0.845,0.41c0.352,0.105,0.761,0.18,1.227,0.223
              c0.466,0.045,0.954,0.066,1.465,0.066c1.038,0,1.979-0.152,2.824-0.461c0.844-0.309,1.566-0.775,2.164-1.398
              c0.598-0.625,1.056-1.414,1.373-2.363c0.317-0.948,0.475-2.066,0.475-3.352c0-1.179-0.171-2.266-0.515-3.26
              c-0.344-0.994-0.841-1.856-1.491-2.587c-0.651-0.729-1.448-1.297-2.389-1.702c-0.941-0.405-2.01-0.607-3.207-0.607
              c-0.528,0-1.047,0.036-1.557,0.106c-0.51,0.07-1.021,0.175-1.531,0.316L6.861,32.344L6.861,32.344z"/>
            <path fill={theme.secondaryTextHighlight} d="M33.625,16.718c1.495,0,2.863,0.207,4.104,0.62c1.241,0.414,2.31,1.008,3.207,1.782
              s1.592,1.729,2.085,2.864c0.492,1.135,0.739,2.415,0.739,3.841c0,1.425-0.247,2.705-0.739,3.84
              c-0.493,1.135-1.188,2.09-2.085,2.862c-0.897,0.775-1.966,1.369-3.207,1.781c-1.241,0.414-2.609,0.621-4.104,0.621
              c-1.496,0-2.864-0.207-4.104-0.621c-1.24-0.412-2.31-1.006-3.207-1.781c-0.897-0.772-1.593-1.729-2.085-2.862
              c-0.493-1.135-0.739-2.415-0.739-3.84c0-1.426,0.246-2.706,0.739-3.841c0.492-1.135,1.188-2.089,2.085-2.864
              c0.897-0.774,1.966-1.368,3.207-1.782C30.761,16.925,32.128,16.718,33.625,16.718z M33.625,17.668
              c-1.092,0-2.037,0.22-2.837,0.66c-0.801,0.44-1.461,1.039-1.979,1.795c-0.52,0.756-0.902,1.623-1.148,2.6
              c-0.247,0.977-0.37,2.01-0.37,3.102c0,1.073,0.123,2.103,0.37,3.088s0.629,1.854,1.148,2.612
              c0.519,0.757,1.179,1.355,1.979,1.793c0.8,0.441,1.746,0.662,2.837,0.662s2.036-0.221,2.837-0.662
              c0.801-0.438,1.46-1.036,1.979-1.793c0.519-0.758,0.902-1.627,1.148-2.612s0.369-2.015,0.369-3.088
              c0-1.092-0.123-2.125-0.369-3.102c-0.247-0.977-0.629-1.843-1.148-2.6s-1.179-1.355-1.979-1.795
              C35.661,17.888,34.715,17.668,33.625,17.668z"/>
            <path fill={theme.secondaryTextHighlight} d="M62.313,22.841c-0.211-0.668-0.488-1.307-0.831-1.914c-0.343-0.606-0.756-1.134-1.24-1.583
              c-0.484-0.45-1.056-0.805-1.715-1.069c-0.66-0.264-1.43-0.396-2.31-0.396c-1.108,0-2.063,0.225-2.864,0.674
              c-0.801,0.448-1.46,1.042-1.979,1.781s-0.902,1.584-1.148,2.534c-0.247,0.95-0.37,1.936-0.37,2.957
              c0,1.02,0.123,2.005,0.37,2.955c0.246,0.95,0.629,1.795,1.148,2.534c0.519,0.738,1.179,1.332,1.979,1.781
              c0.801,0.447,1.755,0.672,2.864,0.672c0.687,0,1.386-0.145,2.099-0.436c0.713-0.289,1.297-0.672,1.755-1.148v-2.638
              c0-0.37-0.044-0.678-0.132-0.924s-0.238-0.449-0.449-0.606c-0.211-0.158-0.497-0.287-0.857-0.383
              c-0.361-0.097-0.823-0.18-1.386-0.25v-0.634c0.599,0.036,1.254,0.065,1.966,0.092c0.713,0.027,1.5,0.04,2.362,0.04
              c0.845,0,1.627-0.013,2.349-0.04c0.723-0.026,1.373-0.057,1.952-0.092v0.634c-0.791,0.036-1.367,0.217-1.729,0.541
              c-0.358,0.326-0.539,0.735-0.539,1.227v4.014c-0.688,0.211-1.333,0.422-1.94,0.633c-0.606,0.211-1.205,0.4-1.794,0.566
              c-0.59,0.168-1.184,0.305-1.782,0.41c-0.599,0.104-1.232,0.158-1.9,0.158c-1.496,0-2.864-0.207-4.104-0.621
              c-1.241-0.412-2.31-1.006-3.207-1.781c-0.897-0.773-1.593-1.729-2.085-2.863c-0.493-1.135-0.739-2.415-0.739-3.84
              c0-1.426,0.247-2.706,0.739-3.841c0.492-1.135,1.188-2.089,2.085-2.864c0.897-0.774,1.966-1.368,3.207-1.782
              c1.24-0.413,2.608-0.62,4.104-0.62c1.073,0,2.12,0.118,3.141,0.356c1.02,0.237,2.032,0.445,3.034,0.62
              c0,0.475,0.014,0.924,0.04,1.347c0.026,0.422,0.065,0.835,0.119,1.24c0.052,0.404,0.118,0.818,0.197,1.24
              c0.079,0.423,0.181,0.862,0.303,1.32L62.313,22.841L62.313,22.841z"/>
            <path fill={theme.secondaryTextHighlight} d="M10.04,41.551c-0.132-0.49-0.348-0.947-0.646-1.373c-0.298-0.424-0.644-0.797-1.035-1.113
              c-0.392-0.317-0.819-0.569-1.284-0.756C6.611,38.123,6.14,38.03,5.663,38.03c-0.597,0-1.105,0.155-1.522,0.467
              c-0.418,0.312-0.627,0.778-0.627,1.403c0,0.478,0.188,0.882,0.567,1.214c0.378,0.331,0.852,0.633,1.422,0.905
              c0.57,0.271,1.184,0.547,1.841,0.824c0.657,0.279,1.27,0.613,1.84,1.006c0.57,0.392,1.044,0.857,1.422,1.402
              s0.567,1.221,0.567,2.029c0,0.57-0.113,1.088-0.338,1.553c-0.226,0.463-0.544,0.857-0.955,1.184
              c-0.412,0.324-0.909,0.576-1.492,0.756c-0.584,0.181-1.233,0.269-1.95,0.269c-0.331,0-0.663-0.028-0.995-0.088
              c-0.332-0.062-0.66-0.127-0.985-0.199c-0.325-0.073-0.654-0.141-0.985-0.198c-0.332-0.062-0.663-0.091-0.995-0.091
              c-0.053,0-0.123,0.011-0.209,0.029c-0.086,0.021-0.179,0.043-0.279,0.069c-0.099,0.027-0.196,0.054-0.289,0.08
              c-0.093,0.025-0.166,0.054-0.219,0.078c-0.106-0.742-0.219-1.492-0.338-2.248s-0.272-1.52-0.458-2.287h0.438
              c0.146,0.49,0.385,0.976,0.717,1.451c0.332,0.479,0.719,0.906,1.164,1.283c0.444,0.379,0.926,0.682,1.443,0.906
              c0.517,0.227,1.027,0.338,1.532,0.338c0.438,0,0.836-0.033,1.194-0.1C7.533,50,7.841,49.891,8.1,49.738
              c0.259-0.153,0.458-0.357,0.597-0.617c0.139-0.258,0.208-0.586,0.208-0.984c0-0.543-0.192-0.998-0.577-1.363
              c-0.385-0.364-0.866-0.694-1.443-0.993c-0.577-0.3-1.201-0.589-1.87-0.865c-0.67-0.279-1.293-0.599-1.87-0.955
              c-0.577-0.358-1.058-0.783-1.443-1.272c-0.385-0.492-0.577-1.095-0.577-1.812c0-1.248,0.378-2.151,1.135-2.717
              c0.756-0.564,1.903-0.847,3.442-0.847c0.398,0,0.782,0.031,1.154,0.09c0.372,0.062,0.723,0.13,1.055,0.209
              c0.331,0.08,0.643,0.15,0.935,0.209c0.292,0.062,0.563,0.091,0.816,0.091h0.716c-0.013,0.225-0.027,0.438-0.04,0.637
              c-0.014,0.199-0.02,0.404-0.02,0.617c0,0.385,0.017,0.78,0.05,1.192c0.033,0.412,0.062,0.81,0.089,1.193H10.04z"/>
            <path fill={theme.secondaryTextHighlight} d="M12.547,50.246c0.398-0.027,0.729-0.062,0.995-0.109s0.478-0.141,0.637-0.279
              c0.159-0.139,0.271-0.33,0.338-0.575c0.067-0.246,0.1-0.574,0.1-0.986v-7.76c0-0.478-0.017-0.861-0.05-1.154
              c-0.033-0.291-0.119-0.522-0.259-0.694c-0.139-0.172-0.345-0.306-0.617-0.398c-0.272-0.092-0.64-0.164-1.104-0.219v-0.478
              c1.141,0.078,2.288,0.119,3.442,0.119c1.154,0,2.295-0.041,3.422-0.119v0.478c-0.491,0.055-0.882,0.127-1.174,0.219
              c-0.292,0.094-0.511,0.227-0.657,0.398c-0.146,0.17-0.239,0.403-0.279,0.694c-0.04,0.293-0.06,0.679-0.06,1.154v2.627h6.606
              v-2.627c0-0.478-0.02-0.861-0.06-1.154c-0.04-0.291-0.129-0.522-0.269-0.694s-0.352-0.306-0.637-0.398
              c-0.286-0.092-0.68-0.164-1.184-0.219v-0.478c1.181,0.078,2.348,0.119,3.502,0.119c1.154,0,2.294-0.041,3.422-0.119v0.478
              c-0.491,0.055-0.875,0.127-1.154,0.219c-0.279,0.094-0.488,0.227-0.627,0.398c-0.139,0.17-0.229,0.403-0.269,0.694
              c-0.04,0.293-0.06,0.679-0.06,1.154v7.76c0,0.412,0.033,0.74,0.1,0.986c0.067,0.245,0.179,0.438,0.338,0.575
              c0.159,0.139,0.375,0.232,0.646,0.279c0.272,0.047,0.614,0.082,1.025,0.109v0.477c-0.57-0.024-1.144-0.045-1.721-0.059
              s-1.151-0.02-1.721-0.02c-0.571,0-1.141,0.006-1.711,0.02c-0.57,0.014-1.141,0.033-1.711,0.059v-0.477
              c0.397-0.027,0.729-0.062,0.995-0.109c0.265-0.047,0.48-0.141,0.646-0.279c0.166-0.138,0.282-0.33,0.348-0.575
              c0.066-0.246,0.1-0.574,0.1-0.986v-4.297h-6.606v4.297c0,0.412,0.033,0.74,0.1,0.986c0.067,0.245,0.182,0.438,0.348,0.575
              c0.166,0.139,0.385,0.232,0.657,0.279c0.271,0.047,0.607,0.082,1.005,0.109v0.477c-0.584-0.024-1.164-0.045-1.741-0.059
              s-1.157-0.02-1.741-0.02c-0.557,0-1.118,0.006-1.681,0.02c-0.564,0.014-1.125,0.033-1.682,0.059v-0.477H12.547z"/>
            <path fill={theme.secondaryTextHighlight} d="M37.518,37.312c1.127,0,2.159,0.155,3.094,0.469c0.936,0.311,1.741,0.76,2.418,1.342
              c0.676,0.584,1.2,1.305,1.572,2.16c0.371,0.855,0.557,1.82,0.557,2.895c0,1.075-0.186,2.039-0.557,2.896
              c-0.372,0.854-0.896,1.573-1.572,2.157c-0.677,0.584-1.482,1.031-2.418,1.345c-0.935,0.311-1.966,0.467-3.094,0.467
              s-2.159-0.156-3.094-0.467c-0.935-0.312-1.741-0.761-2.417-1.345c-0.677-0.584-1.201-1.303-1.572-2.157
              c-0.372-0.855-0.557-1.82-0.557-2.896c0-1.074,0.185-2.039,0.557-2.895c0.371-0.855,0.896-1.576,1.572-2.16
              c0.676-0.582,1.482-1.031,2.417-1.342C35.358,37.469,36.39,37.312,37.518,37.312z M37.518,38.029
              c-0.823,0-1.536,0.166-2.139,0.496c-0.604,0.332-1.102,0.783-1.492,1.354c-0.392,0.568-0.68,1.224-0.866,1.959
              c-0.186,0.736-0.278,1.517-0.278,2.338c0,0.81,0.093,1.587,0.278,2.328c0.186,0.742,0.474,1.397,0.866,1.97
              c0.391,0.569,0.888,1.021,1.492,1.354c0.603,0.332,1.316,0.498,2.139,0.498c0.822,0,1.535-0.166,2.139-0.498
              c0.603-0.332,1.101-0.783,1.492-1.354s0.68-1.228,0.865-1.97c0.186-0.741,0.279-1.521,0.279-2.328
              c0-0.821-0.093-1.602-0.279-2.338c-0.186-0.735-0.474-1.391-0.865-1.959c-0.392-0.571-0.889-1.022-1.492-1.354
              C39.053,38.195,38.34,38.029,37.518,38.029z"/>
            <path fill={theme.secondaryTextHighlight} d="M45.994,37.592c0.438,0.025,0.872,0.053,1.303,0.078c0.431,0.027,0.866,0.041,1.303,0.041
              c1.075,0,2.149-0.041,3.224-0.119v0.477c-0.173,0-0.342,0.009-0.507,0.021c-0.166,0.015-0.319,0.056-0.458,0.121
              c-0.14,0.065-0.252,0.158-0.338,0.276c-0.086,0.121-0.13,0.294-0.13,0.519c0,0.041,0.05,0.209,0.149,0.509
              c0.1,0.299,0.232,0.68,0.398,1.143c0.166,0.467,0.354,0.99,0.567,1.572c0.212,0.584,0.428,1.182,0.646,1.791
              c0.219,0.61,0.435,1.211,0.647,1.801c0.211,0.59,0.398,1.133,0.557,1.621h0.04l2.149-5.551c-0.292-0.822-0.544-1.474-0.756-1.949
              c-0.212-0.477-0.415-0.847-0.607-1.105c-0.192-0.258-0.395-0.438-0.607-0.535c-0.212-0.1-0.471-0.176-0.775-0.23v-0.477
              c0.981,0.078,1.95,0.119,2.905,0.119c0.557,0,1.117-0.014,1.681-0.041c0.563-0.025,1.125-0.053,1.681-0.078v0.477
              c-0.159,0.015-0.345,0.029-0.557,0.052c-0.212,0.021-0.412,0.062-0.597,0.129c-0.186,0.065-0.345,0.162-0.478,0.289
              c-0.133,0.125-0.199,0.301-0.199,0.526c0,0.105,0.06,0.355,0.179,0.746c0.12,0.392,0.271,0.861,0.458,1.412
              c0.185,0.551,0.394,1.142,0.626,1.771c0.232,0.629,0.458,1.242,0.677,1.84c0.219,0.598,0.424,1.137,0.617,1.621
              c0.192,0.484,0.334,0.854,0.428,1.105c0.08-0.188,0.209-0.494,0.388-0.927c0.179-0.432,0.381-0.922,0.607-1.473
              c0.225-0.552,0.457-1.136,0.696-1.75c0.238-0.617,0.457-1.201,0.656-1.752c0.198-0.552,0.36-1.041,0.486-1.474
              c0.127-0.43,0.189-0.737,0.189-0.926c0-0.238-0.057-0.43-0.169-0.576c-0.112-0.145-0.256-0.262-0.428-0.348
              c-0.173-0.086-0.352-0.148-0.537-0.189c-0.187-0.039-0.358-0.065-0.518-0.08v-0.477c0.425,0.025,0.839,0.053,1.243,0.078
              c0.405,0.027,0.819,0.041,1.244,0.041c0.385,0,0.767-0.014,1.146-0.041c0.377-0.025,0.758-0.053,1.144-0.078v0.477
              c-0.318,0.041-0.583,0.104-0.796,0.189s-0.398,0.203-0.557,0.35c-0.16,0.146-0.304,0.314-0.429,0.507
              c-0.127,0.192-0.256,0.422-0.39,0.686c-0.131,0.267-0.317,0.664-0.557,1.195c-0.238,0.53-0.504,1.137-0.797,1.819
              c-0.291,0.685-0.6,1.406-0.924,2.17c-0.326,0.763-0.637,1.513-0.935,2.248c-0.299,0.736-0.574,1.425-0.826,2.068
              c-0.252,0.645-0.458,1.178-0.617,1.602h-0.697l-2.945-8.098c-0.517,1.379-1.064,2.732-1.641,4.06
              c-0.577,1.325-1.151,2.674-1.721,4.038h-0.597l-3.88-10.823c-0.093-0.279-0.192-0.537-0.298-0.774
              c-0.106-0.238-0.236-0.447-0.388-0.628c-0.153-0.18-0.338-0.321-0.557-0.428c-0.219-0.104-0.495-0.166-0.826-0.181v-0.477H45.994
              z"/>
          </g>
        </g>
      </g>
    </Logo>
  );
}

Logo.propTypes = {
  oneColor: PropTypes.string
}