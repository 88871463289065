import React from 'react';
import theme from '../../lib/common/theme';
import styled from '@emotion/styled';


export default function Highlight({ children }) {
  const Highlight = styled.span`
    color: ${theme.primaryTextHighlight1};
    font-weight: 800;
  `;

  return (
    <Highlight>{children}</Highlight>
  );
}

