import React from 'react';
import styled from '@emotion/styled';
import theme from '../../lib/common/theme';

const Container = styled.div`
  min-width: ${theme.formMinWidth};
  max-width: ${theme.formMaxWidth};
  padding: ${theme.medium};
  margin: auto;
`;

export default function FormContainer({children}) {

  return (
    <Container>{children}</Container>
  );
}