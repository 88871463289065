import React from 'react';
import theme from '../../lib/common/theme';
import styled from '@emotion/styled';


export default function ListContainer({ children }) {
  const ListContainer = styled.ul`
    margin-bottom: ${theme.small};
  `;

  return (
    <ListContainer>{children}</ListContainer>
  );
}

