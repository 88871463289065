import React from 'react';
import PropTypes from 'prop-types';

export default function Chip({onRemove, labelValues, value}) {
  let labelValue = labelValues.find(labelValue => labelValue.value === value);

  if (!labelValue) {
    return null;
  }

  return (
    <div className="chip-body">
        <p className="chip-text">{labelValue.label}</p>
        <button className="chip-close" onClick={() => onRemove(value)}>&times;</button>
    </div>
  );
}

Chip.propTypes = {
  onRemove: PropTypes.func.isRequired,
  labelValues: PropTypes.array.isRequired,
  value: PropTypes.string.isRequired
}