import React, {useState} from 'react';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import Loading from '../components/common/loading';
import Error from '../components/common/error';
import TrialPicker from '../components/trial/trialPicker';
import Title from '../components/common/title';
import styled from '@emotion/styled';
import FakeLink from '../components/common/fakeLink';
import theme from '../lib/common/theme';
import DateHelper from '../lib/common/dateHelper';

const GET_TRIALS = gql`
  query GetTrials($isPublish: Boolean!) {
    getTrials(isPublish: $isPublish) {
      _id
      trialType
      name
      startDate
      endDate
      entryCloseDate
      description
      addressLine
      city
      state
      zip
      isPublish
      downloadableFiles {
        linkLabel
        url
      }
      client {
        _id
        name
        iconUrl
      }
      herdingEvents {
        _id
        eventNumber 
        eventDate 
        testClasses 
        courses 
        stocks 
        trialClasses 
        trialJudge {
          _id
          name
          judgeNumber
        }
      }
    }
  }
`;

export default function Home() {
  const { data, loading, error } = useQuery(GET_TRIALS, {variables: {isPublish: true}});
  const [showTrialTypes, setShowTrialTypes] = useState('showOpen');

  if (loading) return <Loading />;
  if (error) return <Error />;

  const onSelect = (trial) => {
    let {premiumListUrl} = trial;
    if (premiumListUrl) {
      window.open(premiumListUrl, '_blank');
    }
  };

  const FilterContainer = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: ${theme.medium};
  `;

  const switchShowTrialTypes = (name) => setShowTrialTypes(name);
  
  let today = DateHelper.yyyymmddToday();
  let trials = (data.getTrials || []).filter(({entryCloseDate, endDate}) => {
    if (showTrialTypes === 'showOpen') {
      return today <= entryCloseDate;
    } else if (showTrialTypes === 'showClosed') {
      return today > entryCloseDate && today <= endDate;
    }

    return today > endDate;
  });

  return (
    <div>
      <Title>Herding Trials</Title>
      <FilterContainer>
        <FakeLink name='showOpen' isActive={showTrialTypes === 'showOpen'} onClick={switchShowTrialTypes}>Open Trials</FakeLink>
        <FakeLink name='showClosed' isActive={showTrialTypes === 'showClosed'} onClick={switchShowTrialTypes}>Closed Trials</FakeLink>
        <FakeLink name='showCompleted' isActive={showTrialTypes === 'showCompleted'} onClick={switchShowTrialTypes}>Completed Trials</FakeLink>
      </FilterContainer>
      <TrialPicker trials={trials} onSelect={onSelect} />
    </div>
  );
}