import React from 'react';
import PropTypes from 'prop-types';

export default function CheckBoxListItem({onCheck, labelValue, values}) {
  let {label, value} = labelValue;
  let isChecked = values ? values.findIndex(val => val === value) > -1 : false;

  return (
    <label className="container" key={value}>
      {label}
      <input type="checkbox" value={value} onChange={({target}) => onCheck(target.value, target.checked)} checked={isChecked} />
      <span className="checkmark"></span>
    </label>
  );
}

CheckBoxListItem.propTypes = {
  onCheck: PropTypes.func.isRequired,
  labelValue: PropTypes.object.isRequired,
  values: PropTypes.array
}