import React from 'react';
import PropTypes from 'prop-types';
import Label from './label';
import InputContainer from './inputContainer';
import Input from './input';

export default function TextInput({formName, name, label, value, onChange, disable}) {
  const id = `${formName}-${name}`;

  return (
    <InputContainer>
      <Label htmlFor={id}>{label}</Label>
      <Input id={id} name={name} type='text' value={value} onChange={onChange} disable={disable}/>
    </InputContainer>
  );
}

TextInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  disable: PropTypes.bool
}