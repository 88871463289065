import React from 'react';
import styled from '@emotion/styled';
import theme from '../../lib/common/theme';

export default function Title({children}) {
  const Title = styled.div`
    font-size: ${theme.larger};
    color: ${theme.secondaryTextHighlight};
    text-align: center;
    margin-bottom: ${theme.medium};
  `;

  return (
    <Title>{children}</Title>
  );
}