import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import TextInput from '../common/textInput';
import DateInput from '../common/dateInput';
import DateHelper from '../../lib/common/dateHelper';
import RadioInput from '../common/radioInput';
import TrialTile from './trialTile';
import FormContainer from '../common/formContainer';
import Title from '../common/title';
import ButtonContainer from '../common/buttonContainer';
import Button from '../common/button';
import FormWithPreviewContainer from '../common/formWithPreviewContainer';
import SpaceContainer from '../common/spaceContainer';
import FileUploadInput from '../common/fileUploadInput';
import SubTitle from '../common/subTitle';
import theme from '../../lib/common/theme';

const ADD_TRIAL = gql`
  mutation AddTrial($trialType: String!, $name: String!, $clientId: String!, $startDate: Int!, $endDate: Int!, $entryCloseDate: Int!, $description: String, $addressLine: String!, $city: String!, $state: String!, $zip: String!, $isPublish: Boolean!, $downloadableFiles: [DownloadableFileInput]) {
    addTrial(trialType: $trialType, name: $name, clientId: $clientId, startDate: $startDate, endDate: $endDate, entryCloseDate: $entryCloseDate, description: $description, addressLine: $addressLine, city: $city, state: $state, zip: $zip, isPublish: $isPublish, downloadableFiles: $downloadableFiles) {
      _id
    }
  }
`;

const UPDATE_TRIAL = gql`
  mutation UpdateTrial($_id: ID!, $trialType: String!, $name: String!, $clientId: String!, $startDate: Int!, $endDate: Int!, $entryCloseDate: Int!, $description: String, $addressLine: String!, $city: String!, $state: String!, $zip: String!, $isPublish: Boolean!, $downloadableFiles: [DownloadableFileInput]) {
    updateTrial(_id: $_id, trialType: $trialType, name: $name, clientId: $clientId, startDate: $startDate, endDate: $endDate, entryCloseDate: $entryCloseDate, description: $description, addressLine: $addressLine, city: $city, state: $state, zip: $zip, isPublish: $isPublish, downloadableFiles: $downloadableFiles) {
      _id
    }
  }
`;


export default function TrialForm({onContinue, onCancel, currentTrial})  {
  const [addTrial] = useMutation(ADD_TRIAL);
  const [updateTrial] = useMutation(UPDATE_TRIAL);
  const [trial, setTrial] = useState(currentTrial);
  const [errorMessage, setErrorMessage] = useState('');
  const setForm = ({target: {name, value}}) => setTrial({...trial, [name]: value});
  const setStartDate = (date) => setTrial({...trial, startDate: DateHelper.DateToyyyymmdd(date)});
  const setEndDate = (date) => setTrial({...trial, endDate: DateHelper.DateToyyyymmdd(date)});
  const setEntryCloseDate = (date) => setTrial({...trial, entryCloseDate: DateHelper.DateToyyyymmdd(date)});

  const setDownloadableFiles = ({target}) => {
    let id = Number(target.id.split('-')[0]);
    let downloadableFiles = trial.downloadableFiles.map(downloadableFile => {
      if (downloadableFile.id === id) {
        return {...downloadableFile, [target.name]: target.value}
      }
      
      return downloadableFile;
    });
    setTrial({...trial, downloadableFiles});
  };

  useEffect(() => {
    setTrial(currentTrial);
  }, [currentTrial]);

  const onSave = (afterSave) => {
    trial.downloadableFiles = trial.downloadableFiles.filter(({url}) => !!url).map(({linkLabel, url}) => ({linkLabel, url}));

    if (trial._id) {
      updateTrial({ variables: { ...trial, clientId: trial.clientId || trial.client._id }})
        .then(result => afterSave(result))
        .catch(error => setErrorMessage(error));
    } else {
      addTrial({ variables: { ...trial, clientId: trial.clientId || trial.client._id }})
        .then(result => afterSave(result))
        .catch(error => setErrorMessage(error));
    }
  }

  const onSaveAndExit = () => {
    onSave(onCancel);
  }

  const onSaveAndContinue = () => {
    const afterSave = ({data}) => {
      if (trial._id) {
        !!data.updateTrial ? onContinue(data.updateTrial) : setErrorMessage(`Could not update the existing trial with id: ${currentTrial._id}`);
      } else {
        !!data.addTrial ? onContinue(data.addTrial) : setErrorMessage('Could not add a new trial');
      }
    };

    onSave(afterSave);
  }

  const fileRowStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    padding: `${theme.small} 0`,
    margin: `${theme.small} 0`
  }

  const renderFiles = () => {
    return trial.downloadableFiles.map((downloadableFile, index) => {
      return (
        <div key={downloadableFile.id || index} style={fileRowStyle}>
          <TextInput formName={`${downloadableFile.id}-trial`} name='linkLabel' label='Link Label' value={downloadableFile.linkLabel} onChange={setDownloadableFiles} />
          <FileUploadInput formName={`${downloadableFile.id}-trial`} name='url' label='Upload File' accept='pdf' value={downloadableFile.url} onChange={setDownloadableFiles} />
        </div>
      );
    })    
  }

  return (
    <FormWithPreviewContainer>
      <FormContainer>
        <SpaceContainer>
          <RadioInput name='trialType' label='Trial Type' labelValues={[{label: 'Herding', value: 'herding'}, {label: 'Agility', value: 'agility', isDisabled: true}, {label: 'Show', value: 'show', isDisabled: true}]} value={trial.trialType} onChange={setForm} />
          <TextInput formName='trial' name='name' label='Name' value={trial.name} onChange={setForm} />
          <DateInput formName='trial' label='Start Date' value={Number(trial.startDate)} onChange={setStartDate} />
          <DateInput formName='trial' label='End Date' value={Number(trial.endDate)} onChange={setEndDate} />
          <DateInput formName='trial' label='Entry Close Date' value={Number(trial.entryCloseDate)} onChange={setEntryCloseDate} />
          <TextInput formName='trial' name='description' label='Description' value={trial.description} onChange={setForm} />
          <TextInput formName='trial' name='addressLine' label='Address Line' value={trial.addressLine} onChange={setForm} />
          <TextInput formName='trial' name='city' label='City' value={trial.city} onChange={setForm} />
          <TextInput formName='trial' name='state' label='State' value={trial.state} onChange={setForm} />
          <TextInput formName='trial' name='zip' label='Zip' value={trial.zip} onChange={setForm} />
        </SpaceContainer>

        <SpaceContainer>
          <SubTitle>Files</SubTitle>
          {renderFiles()}
        </SpaceContainer>
        
        <ButtonContainer justifyContent='space-around'>
          <Button minWidth={200} onClick={onCancel}>Cancel</Button>
          <Button minWidth={200} name='saveAndExit' onClick={onSaveAndExit}>Save and Exit</Button>
          <Button minWidth={200} name='saveAndContinue' onClick={onSaveAndContinue}>Save and Continue</Button>
        </ButtonContainer>
        
        <div>
          {errorMessage}
        </div>
      </FormContainer>

      <SpaceContainer>
        <Title>Preview Trial Tile</Title>
        <TrialTile trial={trial} />
      </SpaceContainer>
    </FormWithPreviewContainer>
  );
}

TrialForm.propTypes = {
  onContinue: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  currentTrial: PropTypes.object.isRequired
}