import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import TrialPicker from '../../../components/trial/trialPicker';
import Error from '../../../components/common/error';
import Loading from '../../../components/common/loading';
import Title from '../../../components/common/title';
import SubTitle from '../../../components/common/subTitle';
import LinkButton from '../../../components/common/linkButton';
import ButtonContainer from '../../../components/common/buttonContainer';
import theme from '../../../lib/common/theme';
import styled from '@emotion/styled';
import FakeLink from '../../../components/common/fakeLink';
import DateHelper from '../../../lib/common/dateHelper';

const GET_TRIALS = gql`
  query($clientId: ID!) {
    getTrials(clientId: $clientId) {
      _id
      trialType
      name
      startDate
      endDate
      entryCloseDate
      description
      addressLine
      city
      state
      zip
      isPublish
      downloadableFiles {
        linkLabel
        url
      }
      client {
        _id
        name
        iconUrl
      }
      herdingEvents {
        _id
        eventNumber 
        eventDate 
        testClasses 
        courses 
        stocks 
        trialClasses 
        trialJudge {
          _id
          name
          judgeNumber
        }
      }
    }
  }
`;

export default function CreateEditTrial({ history, clientId }) {
  const { data, loading, error } = useQuery(GET_TRIALS, {
    fetchPolicy: 'no-cache',
    variables: {clientId}
  });

  const [showTrialTypes, setShowTrialTypes] = useState('showOpen');

  if (loading) return <Loading />;
  if (error) return <Error />;

  const onSelect = ({_id}) => history.push(`/admin/trial/${_id}`);

const FilterContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: ${theme.medium};
`;

const switchShowTrialTypes = (name) => setShowTrialTypes(name);

let today = DateHelper.yyyymmddToday();
let trials = (data.getTrials || []).filter(({entryCloseDate, endDate}) => {
  if (showTrialTypes === 'showOpen') {
    return today <= entryCloseDate;
  } else if (showTrialTypes === 'showClosed') {
    return today > entryCloseDate && today <= endDate;
  }

  return today > endDate;
});

  return (
    <div>
      <Title>Administer Trials</Title>

      <ButtonContainer bottomMargin={theme.larger}>
        <LinkButton to="/admin/trial/new">Create Trial</LinkButton>
      </ButtonContainer>

      <SubTitle>Edit Existing Trial</SubTitle>
      <FilterContainer>
        <FakeLink name='showOpen' isActive={showTrialTypes === 'showOpen'} onClick={switchShowTrialTypes}>Open Trials</FakeLink>
        <FakeLink name='showClosed' isActive={showTrialTypes === 'showClosed'} onClick={switchShowTrialTypes}>Closed Trials</FakeLink>
        <FakeLink name='showCompleted' isActive={showTrialTypes === 'showCompleted'} onClick={switchShowTrialTypes}>Completed Trials</FakeLink>
      </FilterContainer>
      <TrialPicker trials={trials} onSelect={onSelect} />
    </div>
  );
}

CreateEditTrial.propTypes = {
  clientId: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired
}