import React from 'react';
import { GoogleLogin } from 'react-google-login';
import { GoogleLogout } from 'react-google-login';
// import FacebookLogin from 'react-facebook-login';
import PropTypes from 'prop-types';
import './auth.scss';
import Button from '../common/button';
import DesktopText from '../common/DesktopText';

export default function Auth({ onLogin, onLogout, currentUser }) {
  const buttonMediaQuery = `
    @media (min-width: 500px) {
      min-width: 260px;
    }
  `;

  if (currentUser) {
    if (currentUser.loginType === 'google') {
      return (
        <GoogleLogout
          clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
          render={({onClick}) =>(<Button onClick={onClick} minWidth={62} mediaQuery={buttonMediaQuery}>Logout <DesktopText>{currentUser.name}</DesktopText></Button>)}
          onLogoutSuccess={onLogout} />
      );
    } else if(currentUser.loginType === 'facebook') {
      return (
        <button className='facebookLogout' onClick={onLogout}>Logout <DesktopText>{currentUser.name}</DesktopText></button>
      );
    }
  } else {
    return (
      <div>
        <GoogleLogin
          clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
          render={({onClick}) =>(<Button onClick={onClick} minWidth={62} mediaQuery={buttonMediaQuery}><DesktopText>Trial Secretary</DesktopText> Login</Button>)}
          onSuccess={response => {
            onLogin({
              name: response.profileObj.name, 
              email: response.profileObj.email, 
              accessToken: response.tokenObj.id_token,
              expiresIn: new Date().valueOf() + (Number(response.tokenObj.expires_in) * 10000),
              loginType: 'google'}
            );
          }}
          onFailure={(response) => console.warn(response)}
          cookiePolicy={'single_host_origin'}
        />
        {/* <FacebookLogin
          appId={process.env.REACT_APP_FACEBOOK_APP_ID}
          fields="name,email,picture"
          callback={(response) => {
            onLogin({
              name: response.name,
              email: response.email,
              accessToken: response.accessToken,
              expiresIn: new Date().valueOf() + (response.expiresIn * 10000),
              loginType: 'facebook'
            });
          }} /> */}
      </div>
    );
  }
}


Auth.propTypes = {
  onLogin: PropTypes.func.isRequired,
  onLogout: PropTypes.func.isRequired,
  currentUser: PropTypes.object
}