import React from 'react';
import PageHeader from '../components/common/pageHeader';
import LinkButton from '../components/common/linkButton';

export default function FourOFour() {
  
  return (
    <div>
      <PageHeader>Page Not Found</PageHeader>
      <LinkButton to="/">Go to Home</LinkButton>
    </div>
  );
}