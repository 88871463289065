import React from 'react';
import PropTypes from 'prop-types';
import TableCell from '../common/tableCell';
import Button from '../common/button';
import theme from '../../lib/common/theme';

export default function ClientRow({onUpdate, onGhost, client}) {
  return (
    <tr>
      <TableCell>{client._id}</TableCell>
      <TableCell>{client.name}</TableCell>
      <TableCell>{client.email}</TableCell>
      <TableCell>{client.addressLine}</TableCell>
      <TableCell>{client.city}</TableCell>
      <TableCell>{client.state}</TableCell>
      <TableCell>{client.zip}</TableCell>
      <TableCell>{client.iconUrl}</TableCell>
      <TableCell>{client.isActive ? 'Active' : 'Inactive'}</TableCell>
      <TableCell>
        <Button minWidth={50} margin={theme.xsmall} onClick={() => onUpdate(client)}>Edit</Button>
        <Button minWidth={50} margin={theme.xsmall} onClick={() => onGhost(client._id)}>Ghost</Button>
      </TableCell>
    </tr>
  );
}

ClientRow.propTypes = {
  onUpdate: PropTypes.func.isRequired,
  onGhost: PropTypes.func.isRequired,
  client: PropTypes.object.isRequired
}