import React, { useState } from 'react';
import PropTypes from 'prop-types';
import JudgeRow from './judgeRow';
import TextInput from '../common/textInput';
import Table from '../common/table';
import TableHeader from '../common/tableHeader';
import SubTitle from '../common/subTitle';
import FormContainer from '../common/formContainer';

export default function ListJudges({onUpdate, judges}) {
  const [filterString, setFilterString] = useState('');
  const filteredJudges = judges.filter(({name, judgeNumber}) => name.indexOf(filterString) > -1 || judgeNumber.indexOf(filterString) > -1).slice(0, 10);
  const onFilterChange = ({target: {value}}) => setFilterString(value);

  return (
    <div>
      <SubTitle>Existing Judges (Showing {filteredJudges.length} out of {judges.length})</SubTitle>

      <FormContainer>
        <TextInput formName='listJudges' name='filterJudge' label='Filter Judges' value={filterString} onChange={onFilterChange} />
      </FormContainer>
      
      <Table>
        <thead>
          <tr>
            <TableHeader>Name</TableHeader>
            <TableHeader>Judge Number</TableHeader>
            <TableHeader>Type of Judge</TableHeader>
            <TableHeader>Is Active</TableHeader>
          </tr>
        </thead>
        <tbody>
          {filteredJudges.map(judge => (<JudgeRow key={judge._id} onUpdate={onUpdate} judge={judge} />))}
        </tbody>
      </Table>
    </div>
  );
}

ListJudges.propTypes = {
  onUpdate: PropTypes.func.isRequired,
  judges: PropTypes.array
}