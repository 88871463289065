import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Trials from './trials';
import styled from '@emotion/styled';
import TextInput from '../common/textInput';
import theme from '../../lib/common/theme';

const TrialsContainer = styled.div`
  text-align: center;
`;

const InputContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: ${theme.small}
`;

export default function TrialPicker({ onSelect, trials }) {
  const [filterText, setFilterText] = useState('');
  const filterHelper = (value) => value.toLowerCase().indexOf(filterText.toLowerCase()) > -1;

  return (
    <div>
      <InputContainer>
        <TextInput name='filterText' label='Filter Trials' value={filterText} onChange={({target: {value}}) => setFilterText(value)}/>
      </InputContainer>
      <TrialsContainer>
        <Trials trials={trials.filter(({client, name, city, state}) => filterText.length === 0 || filterHelper(client.name) || filterHelper(name) || filterHelper(city) || filterHelper(state))} onSelect={onSelect} />
      </TrialsContainer>
    </div>
  );
}

TrialPicker.propTypes = {
  onSelect: PropTypes.func.isRequired,
  trials: PropTypes.array
}