import React from 'react';
import PropTypes from 'prop-types';
import Chip from './chip';

export default function Chips({onRemove, labelValues, values}) {
  if (!values) {
    return null;
  }

  return values.map((value, index) => (
    <Chip key={index} onRemove={onRemove} labelValues={labelValues} value={value} />
  ));
}

Chips.propTypes = {
  onRemove: PropTypes.func.isRequired,
  labelValues: PropTypes.array.isRequired,
  values: PropTypes.array
}