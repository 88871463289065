import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import theme from '../../lib/common/theme';
import Random from 'meteor-random';
import * as Azure from "@azure/storage-blob";
import './fileUploadInput.scss';

export default function FileUploadInput({formName, name, label, value, accept, onChange, clientId}) {
  const fileId = clientId || Random.id();
  const blobContainer = 'files';
  const id = `${formName}-${name}`;
  const saveFile = (file) => {    
    const reader = new FileReader();
    reader.onload = ({target}) => {
      const blobUri = `https://${process.env.REACT_APP_AZURE_STORAGE_ACCOUNT_NAME}.blob.core.windows.net?${process.env.REACT_APP_AZURE_SAS}`;
      const anonymousCredential = new Azure.AnonymousCredential();
      const pipeline = Azure.StorageURL.newPipeline(anonymousCredential);
      const serviceURL = new Azure.ServiceURL(blobUri, pipeline);
      const containerURL = Azure.ContainerURL.fromServiceURL(serviceURL, blobContainer);
      const blobURL = Azure.BlobURL.fromContainerURL(containerURL, `${fileId}/${file.name}`);
      const blockBlobURL = Azure.BlockBlobURL.fromBlobURL(blobURL);

      blockBlobURL.upload(Azure.Aborter.none, target.result, file.size)
        .then(response => {
          if (!response.errorCode) {
            let value = `https://${process.env.REACT_APP_AZURE_STORAGE_ACCOUNT_NAME}.blob.core.windows.net/${blobContainer}/${fileId}/${file.name}`;
            onChange({target: {id, name, value}});
          }
        });
    };

    reader.readAsArrayBuffer(file);
  }

  const onFileChange = ({target}) => saveFile(target.files[0]);

  const Container = styled.div`
    display: flex;
  `;

  const Uploaded = styled.span`
    color: ${theme.primaryText};
    line-height: 36px;
    margin-left: ${theme.small};
  `;

  let fileName = '';

  if (value) {
    let urlParts = value.split('/');
    fileName = urlParts[urlParts.length - 1];
  }

  let displayResults;

  if (!!value) {
    let fileNameParts = fileName.split('.');
    const imageExts = ['jpg', 'jpeg', 'png', 'svg'];
    if (imageExts.findIndex(imageExt => fileNameParts[fileNameParts.length - 1] === imageExt) > -1) {
      displayResults = (<img style={{height: '60px', width: '60px', border: `solid 1px ${theme.primaryTextHighlight2}`}} src={value} alt=''/>);
    } else {
      displayResults = (<Uploaded>{fileName}</Uploaded>);
    }
  } else {
    displayResults = (<Uploaded>{`No ${label} Uploaded`}</Uploaded>);
  }


  return (
    <Container>
      <label htmlFor={id} className='file-label'>{label}</label>
      <input id={id} style={{display: 'none'}} name={name} type='file' accept={accept} onChange={onFileChange}/>
      {displayResults}
    </Container>
  );
}

FileUploadInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  accept: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  clientId: PropTypes.string
}