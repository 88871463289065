import React from 'react';
import styled from '@emotion/styled';
import theme from '../../lib/common/theme';

export default function SubTitle({children}) {
  const SubTitle = styled.div`
    font-size: ${theme.large};
    color: ${theme.secondaryTextHighlight};
    text-align: center;
    margin-bottom: ${theme.medium};
  `;

  return (
    <SubTitle>{children}</SubTitle>
  );
}