import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from "react-router-dom";

export default function IfAdmin({ email, children }) {
  if (email === 'shannonaswanson@gmail.com') {
    return (children);
  }

  return (<Redirect to="/" />);
}

IfAdmin.propTypes = {
  email: PropTypes.string
}