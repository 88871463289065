import React from 'react';
import PropTypes from 'prop-types';
import Label from './label';
import InputContainer from './inputContainer';
import styled from '@emotion/styled';
import theme from '../../lib/common/theme';

const CheckBoxInputTag = styled.input`
  cursor: pointer;
  margin: auto ${theme.small};
`;



export default function CheckBoxInput({formName, name, label, value, onChange}) {
  const id = `${formName}-${name}`;
  const boolOnChange = ({target: {name, checked}}) => onChange({target: {name, value: checked}});

  return (
    <InputContainer>
      <CheckBoxInputTag id={id} name={name} type='checkbox' checked={value} onChange={boolOnChange}/>
      <Label htmlFor={id} cursor='pointer' color={theme.primaryText}>{label}</Label>
    </InputContainer>
  );
}

CheckBoxInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired
}