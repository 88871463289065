import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import FormContainer from '../common/formContainer';
import SpaceContainer from '../common/spaceContainer';
import FileUploadInput from '../common/fileUploadInput';
import TextInput from '../common/textInput';
import CheckBoxInput from '../common/checkBoxInput';
import ButtonContainer from '../common/buttonContainer';
import Button from '../common/button';

const ADD_CLIENT = gql`
  mutation AddClient($name: String!, $email: String!, $phoneNumber: String!, $addressLine: String!, $city: String!, $state: String!, $zip: String!, $isActive: Boolean!, $iconUrl: String) {
    addClient(name: $name, email: $email, phoneNumber: $phoneNumber, addressLine: $addressLine, city: $city, state: $state, zip: $zip, isActive: $isActive, iconUrl: $iconUrl) {
      _id
    }
  }
`;

const UPDATE_CLIENT = gql`
  mutation UpdateClient($_id: ID!, $name: String!, $email: String!, $phoneNumber: String!, $addressLine: String!, $city: String!, $state: String!, $zip: String!, $isActive: Boolean!, $iconUrl: String) {
    updateClient(_id: $_id, name: $name, email: $email, phoneNumber: $phoneNumber, addressLine: $addressLine, city: $city, state: $state, zip: $zip, isActive: $isActive, iconUrl: $iconUrl) {
      _id
    }
  }
`;

const DEFAULT_CLIENT = { name: '', email: '', phoneNumber: '', addressLine: '', city: '', state: '', zip: '', iconUrl: '', isActive: false };

export default function ClientForm({onAdd, onSaveAndExit, onCancel, disableEmail, currentClient = DEFAULT_CLIENT, currentUser})  {
  const [addClient] = useMutation(ADD_CLIENT);
  const [updateClient] = useMutation(UPDATE_CLIENT);
  const [client, setClient] = useState(currentClient);
  const [errorMessage, setErrorMessage] = useState('');
  const setForm = ({target: {name, value}}) => setClient({...client, [name]: value});

  const onSave = () => {
    if (currentClient._id) {
      updateClient({ variables: { ...client}})
        .then(({data: {updateClient}}) => {
          if (!!updateClient) {
            if (onSaveAndExit) {
              onSaveAndExit();
            } else {
              onAdd();
            }
          } else {
            setErrorMessage(`Could not update the existing client with id: ${currentClient._id}`);
          }
        })
        .catch(error => setErrorMessage(error));
    } else {
      addClient({ variables: {...client}})
        .then(({data: {addClient}}) => {
          if (!!addClient) {
            if (onSaveAndExit) {
              onSaveAndExit();
            } else {
              onAdd();
            }
          } else {
            setErrorMessage('Could not add a new client');
          }
        })
        .catch(error => setErrorMessage(error));
    }

    setClient(DEFAULT_CLIENT);
  }

  useEffect(() => {
    setClient(currentClient);
  }, [currentClient]);

  const showIsActive = () => {
    if (currentUser.email === process.env.REACT_APP_ADMIN_EMAIL) {
      return (<CheckBoxInput formName='client' name='isActive' label='Is Active' value={client.isActive} onChange={setForm} />);
    }
  }

  return (
    <FormContainer>
      <SpaceContainer>
        <TextInput formName='client' name='name' label='Name' value={client.name} onChange={setForm} />
        <TextInput formName='client' name='email' label='Email' disable={disableEmail} value={client.email} onChange={setForm} />
        <TextInput formName='client' name='phoneNumber' label='Phone Number' value={client.phoneNumber} onChange={setForm} />
        <TextInput formName='client' name='addressLine' label='Address Line' value={client.addressLine} onChange={setForm} />
        <TextInput formName='client' name='city' label='City' value={client.city} onChange={setForm} />
        <TextInput formName='client' name='state' label='State' value={client.state} onChange={setForm} />
        <TextInput formName='client' name='zip' label='Zip' value={client.zip} onChange={setForm} />
        {showIsActive()}
        <FileUploadInput formName='client' name='iconUrl' label='Icon' accept='image/png, image/jpeg, image/jpg, image/svg' value={client.iconUrl || ''} onChange={setForm} folder='clientIcon' />
      </SpaceContainer>
      <ButtonContainer justifyContent='space-around'>
        <Button onClick={onCancel}>Cancel</Button>
        <Button minWidth={60} name='save' onClick={onSave}>Save</Button>
      </ButtonContainer>

      <div>
        {errorMessage}
      </div>
    </FormContainer>
  );
}

ClientForm.propTypes = {
  onAdd: PropTypes.func,
  onSaveAndExit: PropTypes.func,
  onCancel: PropTypes.func,
  disableEmail: PropTypes.bool,
  currentClient: PropTypes.object,
  currentUser: PropTypes.object.isRequired
}
