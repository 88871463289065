import React from 'react';
import PropTypes from 'prop-types';
import ClientIntro from '../components/client/clientIntro';
import Title from '../components/common/title';
import LinkButton from '../components/common/linkButton';
import ButtonContainer from '../components/common/buttonContainer';


export default function ClientHome({ currentUser }) {

  if (currentUser && (currentUser.email === process.env.REACT_APP_ADMIN_EMAIL || currentUser.clientId)) {
    const getUpdateClientsButton = () => {
      if (currentUser.email === process.env.REACT_APP_ADMIN_EMAIL) {
        return (
          <ButtonContainer>
            <LinkButton to={`/admin/client/`} width='300px'>Edit Client's Information</LinkButton>
          </ButtonContainer>
        );
      } else {
        return (
          <ButtonContainer>
            <LinkButton to={`/admin/client/${currentUser.clientId}`} width='300px'>Edit Your Information</LinkButton>
          </ButtonContainer>
        );
      }
    }

    return (
      <div>
        <Title>Trial Secretary Home</Title>
        <ButtonContainer>
          <LinkButton to="/admin/judge" width='300px'>Create or Edit Judges</LinkButton>
        </ButtonContainer>
        <ButtonContainer>
          <LinkButton to="/admin/trial" width='300px'>Create or Edit Trials and Events</LinkButton>
        </ButtonContainer>
        {getUpdateClientsButton()}
      </div>
    );
  }

  return (
    <ClientIntro />
  );
}

ClientHome.propTypes = {
  currentUser: PropTypes.object
}

