import React, {useState} from 'react';
import Logo from './logo';
import theme from '../../lib/common/theme';
import styled from '@emotion/styled';
import useInterval from '../../lib/common/hooks/useInterval';

export default function Loading() {
  let [colorIndex, setColorIndex] = useState(-1);
  let colors = [theme.primaryTextHighlight1, theme.primaryTextHighlight2, theme.secondaryTextHighlight, theme.primaryText];

  useInterval(() => setColorIndex(colorIndex + 1), 500);

  const MiddleContainer = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 35vh;
    transform: scale(2);
  `;

  return (
    <MiddleContainer>
      <Logo oneColor={colors[colorIndex % colors.length]} />
    </MiddleContainer>
  );
}