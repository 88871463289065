import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import theme from '../../lib/common/theme';

export default function Label({htmlFor, color, cursor, title, disabled, children}) {
  const Label = styled.label`
    color: ${color || theme.primaryTextTitle};
    margin-right: ${theme.small};
    white-space: nowrap;
    cursor: ${disabled ? 'not-allowed' : cursor || 'default'};
    opacity: ${disabled ? theme.disableOpacity : 1};
  `;

  return (
    <Label htmlFor={htmlFor} title={title}>{children}</Label>
  );
}

Label.propTypes = {
  htmlFor: PropTypes.string,
  color: PropTypes.string,
  cursor: PropTypes.string,
  title: PropTypes.string,
  disabled: PropTypes.bool
}