import React from 'react';
import ClientRow from './clientRow';
import PropTypes from 'prop-types';
import Table from '../common/table';
import PageHeader from '../common/pageHeader';
import TableHeader from '../common/tableHeader';

export default function ListClients({onUpdate, onGhost, clients}) {

  return (
    <div>
      <PageHeader>Existing Clients</PageHeader>
      <Table>
        <thead>
          <tr>
            <TableHeader>Id</TableHeader>
            <TableHeader>Name</TableHeader>
            <TableHeader>Email</TableHeader>
            <TableHeader>Address Line</TableHeader>
            <TableHeader>City</TableHeader>
            <TableHeader>State</TableHeader>
            <TableHeader>Zip</TableHeader>
            <TableHeader>Icon Url</TableHeader>
            <TableHeader>Is Active</TableHeader>
            <TableHeader>Action</TableHeader>
          </tr>
        </thead>
        <tbody>
          {clients.map(client => (<ClientRow key={client._id} onUpdate={onUpdate} onGhost={onGhost} client={client} />))}
        </tbody>
      </Table>
    </div>
  );
}

ListClients.propTypes = {
  onUpdate: PropTypes.func.isRequired,
  onGhost: PropTypes.func.isRequired,
  clients: PropTypes.array
}