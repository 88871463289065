import React, { useState } from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import Loading from '../../../components/common/loading';
import Error from '../../../components/common/error';
import JudgeForm from '../../../components/judge/judgeForm';
import ListJudges from '../../../components/judge/listJudges';
import PageHeader from '../../../components/common/pageHeader';

const GET_JUDGES = gql`
query {
  getTrialJudges {
    _id
    name
    judgeNumber
    judgeTypes,
    isActive
  }
}
`;

function ManageTrialJudges() {
  const { data, loading, error, refetch } = useQuery(GET_JUDGES);
  const [currentJudge, setCurrentJudge] = useState();
  const onAdd = () => {
    setCurrentJudge(undefined);
    refetch();
  }
  const onUpdate = (judge) => setCurrentJudge(judge);

  if (loading) return <Loading />
  if (error) return <Error />;

  const judgeTypes = [{label: 'Herding', value: 'herding'}, {label: 'Agility', value: 'agility'}];
  let judges = data.getTrialJudges || [];
  judges.sort((a, b) => {
    let aName = a.name.toLowerCase();
    let bName = b.name.toLowerCase();

    if (aName > bName) {
      return 1;
    }
    if (aName < bName) {
      return -1;
    }

    return 0;
  });

  return (
    <div>
      <PageHeader>Manage Judges</PageHeader>
      <JudgeForm onAdd={onAdd} judges={judges} possibleJudgeTypes={judgeTypes} currentJudge={currentJudge} />
      <ListJudges onUpdate={onUpdate} judges={judges} />
    </div>
  );
}

export default ManageTrialJudges