import React, { useState } from 'react';
import ClientForm from '../../../components/client/clientForm';
import ListClients from '../../../components/client/listClients';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import Loading from '../../../components/common/loading';
import Error from '../../../components/common/error';
import PageHeader from '../../../components/common/pageHeader';
import UserSession from '../../../components/common/userSession';
import PropTypes from 'prop-types';

const GET_CLIENTS = gql`
query {
  getClients {
    _id
    name
    email
    phoneNumber
    addressLine
    city
    state
    zip
    isActive
    iconUrl
  }
}
`;

function ManageClients({onGhost}) {
  const { data, loading, error, refetch } = useQuery(GET_CLIENTS);
  const [currentClient, setCurrentClient] = useState();
  const onAdd = () => refetch();
  const onUpdate = (client) => setCurrentClient(client);

  if (loading) return <Loading />
  if (error) return <Error />;

  let clients = data.getClients || [];
  clients.sort((a, b) => {
    if (a.name > b.name) {
      return 1;
    }
    if (a.name < b.name) {
      return -1;
    }

    return 0;
  });

  return (
    <div>
      <PageHeader>Manage Clients</PageHeader>
      <ClientForm currentUser={UserSession.getUser()} onAdd={onAdd} currentClient={currentClient} disableEmail={false} />
      <ListClients onUpdate={onUpdate} onGhost={onGhost} clients={clients} />
    </div>
  );
}

ListClients.propTypes = {
  onGhost: PropTypes.func.isRequired
}

export default ManageClients