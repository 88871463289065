import React from 'react';
import PropTypes from 'prop-types';
import CheckBoxInput from './checkBoxInput';
import Label from './label';
import InputContainer from './inputContainer';

export default function CheckBoxInputs({formName, label, name, labelValues, values, onChange}) {
  let checkBoxInputsOnChange = ({target}) => {
    let newValues = target.value ? values.concat([target.name]) : values.filter(val => val !== target.name);
    onChange({target: {name, value: newValues}});
  };

  return (
    <InputContainer>
      <Label>{label}</Label>
      {labelValues.map(({label, value}) => {
        return (<CheckBoxInput key={value} formName={formName} name={value} label={label} value={values.findIndex(val => val === value) > -1} onChange={checkBoxInputsOnChange}/>);
      })}
    </InputContainer>
  );
}

CheckBoxInputs.propTypes = {
  formName: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  labelValues: PropTypes.array.isRequired,
  values: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired
}