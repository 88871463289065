import React from 'react';
import styled from '@emotion/styled';
import theme from '../../lib/common/theme';

const Container = styled.div`
  margin-bottom: ${theme.small};
  display: flex;
`;

export default function InputContainer({children}) {

  return (
    <Container>{children}</Container>
  );
}