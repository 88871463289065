import React from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import DateHelper from '../../lib/common/dateHelper';
import "react-datepicker/dist/react-datepicker.css";
import Label from './label';
import InputContainer from './inputContainer';
import './dateInput.scss';


export default function DateInput({label, value, onChange}) {
  return (
    <InputContainer>
      <Label>{label}</Label>
      <DatePicker
        className='date-input'
        selected={DateHelper.numberToDate(value)}
        onChange={onChange}
      />
    </InputContainer>
  );
}

DateInput.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired
}