import React, { useState } from 'react';
import gql from 'graphql-tag';
import { useQuery, useMutation } from '@apollo/react-hooks';
import PropTypes from 'prop-types';
import HerdingEventForm from '../../../../components/event/herdingEventForm';
import ListHerdingEvents from '../../../../components/event/listHerdingEvents';
import Error from '../../../../components/common/error';
import Loading from '../../../../components/common/loading';
import DateHelper from '../../../../lib/common/dateHelper';
import Title from '../../../../components/common/title';
import FinalizeTrial from '../../../../components/trial/finalizeTrial';

const GET_JUDGES = gql`
query($isActive: Boolean!) {
  getTrialJudges(isActive: $isActive) {
    _id
    name
    judgeNumber
    judgeTypes,
    isActive
  }
}
`;

const GET_TRIAL = gql`
  query GetTrial($trialId: ID!) {
    getTrial(_id: $trialId) {
      _id
      trialType
      name
      startDate
      endDate
      entryCloseDate
      description
      addressLine
      city
      state
      zip
      isPublish
      client {
        _id
        name
      }
      herdingEvents {
        _id
        eventNumber 
        eventDate 
        testClasses 
        courses 
        stocks 
        trialClasses 
        trialJudge {
          _id
          name
          judgeNumber
        }
      }
    }
  }
`;

const DELETE_HERDING_EVENT = gql`
  mutation DeleteHerdingEvent($_id: ID!) {
    deleteHerdingEvent(_id: $_id) {
      _id
    }
  }
`;

export default function ManageEvents({ match }) {
  const {params: {trialId}} = match;
  const judgeQuery = useQuery(GET_JUDGES, {variables: {isActive: true}});
  const DEFAULT_EVENT = { trialId, eventNumber: '', name: '', eventDate: DateHelper.yyyymmddToday(), testClasses: [], courses: [], stocks: [], trialClasses: [], trialJudgeId: '' };
  const { data, loading, error, refetch } = useQuery(GET_TRIAL, {variables: {trialId}});
  const [currentEvent, setCurrentEvent] = useState(DEFAULT_EVENT);
  const [deleteHerdingEvent] = useMutation(DELETE_HERDING_EVENT);
  const onAdd = () => refetch();
  const onUpdate = (event) => setCurrentEvent(event);
  const onDelete = ({_id}) => {
    deleteHerdingEvent({ variables: {_id} })
      .then(() => refetch())
      .catch(error => console.error(error));
  };
  const isPublishChanged = () => refetch();

  if (loading || judgeQuery.loading) return <Loading />
  if (error || judgeQuery.error) return <Error />;

  let judges = judgeQuery.data.getTrialJudges || [];
  let trial = data.getTrial || {};
  let events = trial.herdingEvents || [];

  return (
    <div>
      <Title>Manage Events</Title>
      <HerdingEventForm trial={trial} judges={judges} onAdd={onAdd} currentEvent={currentEvent} defaultEvent={DEFAULT_EVENT}/>
      <ListHerdingEvents judges={judges} events={events} onUpdate={onUpdate} onDelete={onDelete} />
      <FinalizeTrial trial={trial} isPublishChanged={isPublishChanged} />
    </div>
  )
}

ManageEvents.propTypes = {
  match: PropTypes.object.isRequired
}
