import React from 'react';
import styled from '@emotion/styled';


export default function ErrorMessage({ children }) {
  const ErrorMessage = styled.div`
    color: red;
  `;

  return (
    <ErrorMessage>{children}</ErrorMessage>
  );
}

