import React from 'react';
import styled from '@emotion/styled';
import theme from '../../lib/common/theme';
import PropTypes from 'prop-types';


export default function ButtonContainer({ children, bottomMargin, justifyContent }) {
  const ButtonContainer = styled.div`
    margin-bottom: ${bottomMargin || theme.medium};
    display: flex;
    justify-content: ${justifyContent || 'center'};
  `;

  return (
    <ButtonContainer>{children}</ButtonContainer>
  )
}

ButtonContainer.propTypes = {
  bottomMargin: PropTypes.string,
  justifyContent: PropTypes.string
}