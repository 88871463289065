import React from 'react';
import theme from '../../lib/common/theme';
import styled from '@emotion/styled';


export default function SubPageHeader({ children }) {
  const SubPageHeader = styled.div`
    color: ${theme.secondaryTextHighlight};
    margin: ${theme.medium} 0;
    font-size: ${theme.large};
  `;

  return (
    <SubPageHeader>{children}</SubPageHeader>
  );
}

