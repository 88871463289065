import React from 'react';
import PropTypes from 'prop-types';
import CheckBoxListItem from './checkBoxListItem';

export default function CheckBoxList({onCheck, labelValues, values}) {

  return labelValues.map((labelValue, index) => 
    <CheckBoxListItem key={index} onCheck={onCheck} labelValue={labelValue} values={values} />
  );
}

CheckBoxList.propTypes = {
  onCheck: PropTypes.func.isRequired,
  labelValues: PropTypes.array.isRequired,
  values: PropTypes.array
}