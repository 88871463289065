import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import theme from '../../lib/common/theme';

export default function FakeLink({isActive, onClick, name, children}) {
  const FakeLink = styled.span`
    color: ${theme.primaryText};
    text-decoration: ${isActive ? 'unset' : 'underline'};
    margin: 0 ${theme.small};
    cursor: ${isActive ? 'not-allowed' : 'pointer'};
  `;

  return (
    <FakeLink onClick={() => onClick(name)}>{children}</FakeLink>
  );
}

FakeLink.propTypes = {
  isActive: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  name: PropTypes.string,
}