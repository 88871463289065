import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import TextInput from '../common/textInput';
import CheckBoxInputs from '../common/checkBoxInputs';
import CheckBoxInput from '../common/checkBoxInput';
import IntInput from '../common/intInput';
import ButtonContainer from '../common/buttonContainer';
import Button from '../common/button';
import FormContainer from '../common/formContainer';
import SubTitle from '../common/subTitle';
import ErrorMessage from '../common/errorMessage';

const ADD_JUDGE = gql`
  mutation AddTrialJudge($name: String!, $judgeNumber: String!, $judgeTypes: [String]!, $isActive: Boolean!) {
    addTrialJudge(name: $name, judgeNumber: $judgeNumber, judgeTypes: $judgeTypes, isActive: $isActive) {
      _id
    }
  }
`;

const UPDATE_JUDGE = gql`
  mutation UpdateTrialJudge($_id: ID!, $name: String!, $judgeNumber: String!, $judgeTypes: [String]!, $isActive: Boolean!) {
    updateTrialJudge(_id: $_id, name: $name, judgeNumber: $judgeNumber, judgeTypes: $judgeTypes, isActive: $isActive) {
      _id
    }
  }
`;

const DEFAULT_JUDGE = { name: '', judgeNumber: '', judgeTypes: [], isActive: true };

export default function JudgeForm({ onAdd, possibleJudgeTypes, judges, currentJudge = DEFAULT_JUDGE }) {

  const [addTrialJudge] = useMutation(ADD_JUDGE);
  const [updateTrialJudge] = useMutation(UPDATE_JUDGE);
  const [judge, setJudge] = useState(currentJudge);
  const [errorMessage, setErrorMessage] = useState('');
  const setForm = ({ target: { name, value } }) => {
    setJudge({ ...judge, [name]: value });
    setErrorMessage('');
  };

  useEffect(() => {
    setJudge(currentJudge);
  }, [currentJudge]);

  const onClick = () => {
    if (currentJudge._id) {
      updateTrialJudge({ variables: { ...judge } })
        .then(({ data: { updateTrialJudge } }) => !!updateTrialJudge ? onAdd() : setErrorMessage(`Could not update the existing judge with id: ${currentJudge._id}`))
        .catch(error => setErrorMessage(error));
        setJudge(DEFAULT_JUDGE);
    } else {
      if (judges.findIndex(({ judgeNumber }) => judgeNumber === judge.judgeNumber) > -1) {
        setErrorMessage(`There already exists a judge with that number`)
      } else {
        addTrialJudge({ variables: { ...judge } })
          .then(({ data: { addTrialJudge } }) => !!addTrialJudge ? onAdd() : setErrorMessage('Could not add a new judge'))
          .catch(error => setErrorMessage(error));
          setJudge(DEFAULT_JUDGE);
      }
    }
  }

  let disableSave = judge.name.length === 0 || judge.judgeNumber.toString().length === 0 || judge.judgeTypes.length === 0;

  return (
    <FormContainer>
      <SubTitle>{judge._id ? `Modify ${judge.name}` : 'Add Judge'}</SubTitle>

      <TextInput formName='judge' name='name' label='Name' value={judge.name} onChange={setForm} />
      <IntInput formName='judge' name='judgeNumber' label='Judge Number' value={judge.judgeNumber.toString()} onChange={setForm} />
      <CheckBoxInputs formName='judge' name='judgeTypes' label={`What does ${!!judge.name ? judge.name : 'this judge'} judge?`} labelValues={possibleJudgeTypes} values={judge.judgeTypes} onChange={setForm} />
      <CheckBoxInput name='isActive' label='Is Active' value={judge.isActive} onChange={setForm} />

      <ButtonContainer>
        <Button minWidth={60} disable={disableSave} onClick={onClick}>Save</Button>
      </ButtonContainer>

      <ErrorMessage>{errorMessage}</ErrorMessage>
    </FormContainer>
  );
}

JudgeForm.propTypes = {
  onAdd: PropTypes.func.isRequired,
  possibleJudgeTypes: PropTypes.array.isRequired,
  judges: PropTypes.array.isRequired,
  currentJudge: PropTypes.object
}