import React from 'react';
import PropTypes from 'prop-types';
import DateHelper from '../../lib/common/dateHelper';
import TableCell from '../common/tableCell';
import Button from '../common/button';
import theme from '../../lib/common/theme';

export default function HerdingEventRow({onUpdate, onDelete, event}) {
  let judgeInfo = event.trialJudge ? `${event.trialJudge.judgeNumber} - ${event.trialJudge.name}` : '';

  return (
    <tr>
      <TableCell>
        <Button minWidth={50} margin={theme.xsmall} onClick={() => onUpdate(event)}>Edit</Button>
      </TableCell>
      <TableCell>{judgeInfo}</TableCell>
      <TableCell>{event.eventNumber}</TableCell>
      <TableCell>{DateHelper.numberToFormattedDate(event.eventDate)}</TableCell>
      <TableCell>{event.courses.join(', ')}</TableCell>
      <TableCell>{event.stocks.join(', ')}</TableCell>
      <TableCell>{event.trialClasses.join(', ')}</TableCell>
      <TableCell>{event.testClasses.join(', ')}</TableCell>
      <TableCell>
        <Button minWidth={70} margin={theme.xsmall} onClick={() => onDelete(event)}>Delete</Button>
      </TableCell>
    </tr>
  );
}

HerdingEventRow.propTypes = {
  onUpdate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  event: PropTypes.object.isRequired
}
