import React from 'react';
import styled from '@emotion/styled';

export default function Table({children}) {
  const Table = styled.table`
    width: 100%;
  `;

  return (
    <Table>{children}</Table>
  );
}