import React from 'react';
import styled from '@emotion/styled';

const Container = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
`;

export default function FormWithPreviewContainer({children}) {
  return (
    <Container>{children}</Container>
  );
}
