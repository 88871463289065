import React from 'react';
import PropTypes from 'prop-types';
import PageHeader from '../common/pageHeader';
import Paragraph from '../common/paragraph';
import Highlight from '../common/highlight';
import ListContainer from '../common/listContainer';
import List from '../common/list';


export default function ClientIntro({ currentUser }) {

  return (
    <div>
      <PageHeader>Trail Secretaries</PageHeader>
      <Paragraph>
        Dog Show 1 is a free website designed to make it easier for Trial Secretaries to list their trials online. 
        To become registered with Dog Show 1 simply email me, <Highlight>Shannon Swanson, at gigaflops.io@gmail.com. </Highlight>  
        Provide your name and phone number and I will contact you on how to use the website and verify that you are 
        registered as a Trail Secretary with AKC.
      </Paragraph>
      <Paragraph>
        Once registered, you will be able to create your trials online.  You will need to provide the following when creating a trial:
      </Paragraph>
      <ListContainer>
        <List>Name of the Trial</List>
        <List>Type of Trail (Only Herding currently, Agility coming soon)</List>
        <List>Trial Dates</List>
        <List>Entry Closing Date</List>
        <List>Location</List>
        <List>Premium List</List>
        <List>Catalog</List>
        <List>Report Form</List>
      </ListContainer>
    </div>
  );
}

ClientIntro.propTypes = {
  currentUser: PropTypes.object
}

