import React from 'react';
import theme from '../../lib/common/theme';
import styled from '@emotion/styled';


export default function List({ children }) {
  const List = styled.li`
    color: ${theme.primaryTextHighlight1};
  `;

  return (
    <List>{children}</List>
  );
}

