import React from 'react';
import PropTypes from 'prop-types';
import ClientForm from '../../../components/client/clientForm';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import Loading from '../../../components/common/loading';
import Error from '../../../components/common/error';
import PageHeader from '../../../components/common/pageHeader';
import UserSession from '../../../components/common/userSession';

const GET_CLIENT = gql`
query GetClient($clientId: ID!) {
  getClient(_id: $clientId) {
    _id
    name
    email
    phoneNumber
    addressLine
    city
    state
    zip
    iconUrl
    isActive
  }
}
`;

export default function ManageClient({id, history}) {
  const { data, loading, error } = useQuery(GET_CLIENT, {
    variables: { clientId: id },
    fetchPolicy: 'no-cache'
  });

  if (loading) return <Loading />
  if (error) return <Error />;

  const goClientHome = () => history.push('/client/home');

  return (
    <div>
      <PageHeader>Manage Your Information</PageHeader>
      <ClientForm currentUser={UserSession.getUser()} currentClient={data.getClient} disableEmail={true} onSaveAndExit={goClientHome} onCancel={goClientHome} />
    </div>
  );
}

ManageClient.propTypes = {
  id: PropTypes.string,
  history: PropTypes.object
}