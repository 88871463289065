import React, {useState} from 'react';
import theme from '../../lib/common/theme';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";

export default function LinkButton({to, children, width}) {
  const [stateObj, setState] = useState({isHover: false, isActive: false});

  const style = {
    display: 'inline-block',
    fontSize: theme.medium,
    color: theme.secondaryTextHighlight,
    textAlign: 'center',
    backgroundColor: stateObj.isActive ? theme.secondaryBackground : theme.primaryBackground,
    border: `solid 1px ${stateObj.isHover ? theme.primaryTextHighlight1 : theme.primaryTextHighlight2}`,
    borderRadius: '2px',
    padding: theme.small,
    textDecoration: 'none',
    fontWeight: stateObj.isHover ? '800' : '400',
    cursor: 'pointer'
  }

  if (width) {
    style.width = width;
  }

  return (
    <Link style={style} to={to}
      onMouseOver={() => setState({...stateObj, isHover: true})} 
      onMouseOut={() => setState({...stateObj, isHover: false})} 
      onMouseUp={() => setState({...stateObj, isActive: true})} 
      onMouseDown={() => setState({...stateObj, isActive: false})}>{children}</Link>
  );
}

LinkButton.propTypes = {
  to: PropTypes.string.isRequired,
  width: PropTypes.string
}