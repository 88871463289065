import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import theme from '../../lib/common/theme';

export default function DownloadFilesRow({trial}) {
  const Row = styled.div`
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    width: 98%;
  `;

  const Link = styled.a`
    color: ${theme.primaryText};
    margin: ${theme.small};
  `;

  const returnLinks = () => {
    return (trial.downloadableFiles || []).map(({linkLabel, url}, index) => {
      return (<Link href={url} key={index}>{linkLabel}</Link>)
    });
  }

  return (
    <Row>{returnLinks()}</Row>
  );

}

DownloadFilesRow.propTypes = {
  trial: PropTypes.object
}