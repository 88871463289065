import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import theme from '../../lib/common/theme';
import DateHelper from '../../lib/common/dateHelper';
import HerdingEventRows from './herdingEventRows';
import DownloadFilesRow from './downloadFilesRow';

export default function TrialTile({ trial, onSelect }) {
  const Tile = styled.div`
    padding: ${theme.medium};
    margin: ${theme.medium};
    background-color: ${theme.primaryBackground};
    display: inline-block;
    border: solid 2px ${theme.primaryTextHighlight2};
    opacity: ${trial.isPublish ? 1 : 0.5};
    cursor: pointer;
    @media (max-width: 768px) {
      width: 90%;
    }
  `;

  const TitleRow = styled.div`
    color: ${theme.primaryText};
    font-size: ${theme.large};
    text-align: center;
    margin-bottom: ${theme.small};
    @media (max-width: 600px) {
      font-size: ${theme.medium};
    }
  `;

  const InfoRow = styled.div`
    display: grid;
    grid-template-columns: 60px auto 150px;
    grid-column-gap: ${theme.medium};
    margin-bottom: ${theme.small};
    padding-bottom: ${theme.small};
    border-bottom: 1px solid ${theme.primaryTextHighlight2};
    @media (max-width: 768px) {
      display: flex;
      justify-content: space-between; 
    }
    @media (max-width: 400px) {
      display: block;
      justify-content: space-between; 
    }
  `;

  const LeftContainer = styled.div`
    text-align: left;
    @media (max-width: 400px) {
      text-align: center;
    }
  `;

  const RightContainer = styled.div`
    text-align: right;
    @media (max-width: 400px) {
      text-align: center;
    }
  `;

  const Logo = styled.div`
    height: 60px;
    width: 60px;
    background-color: white;
    border: solid 1px ${theme.primaryTextHighlight2};
    @media (max-width: 768px) {
      display: none;
    }
  `;

  const MainTitle = styled.div`
    color: ${theme.primaryTextHighlight1};
    font-size: ${theme.large};
    margin-bottom: ${theme.medium};
    @media (max-width: 768px) {
      margin-bottom: ${theme.xsmall};
    }
    @media (max-width: 600px) {
      font-size: ${theme.medium};
    }
  `;

  const Title = styled.div`
    color: ${theme.primaryTextTitle};
    font-size: ${theme.medium};
  `;

  const Text = styled.div`
    color: ${theme.primaryText};
    font-size: ${theme.medium};
  `;

  let trialDateRange = trial.startDate === trial.endDate ? DateHelper.numberToMonthDay(trial.startDate) : `${DateHelper.numberToMonthDay(trial.startDate)} - ${DateHelper.numberToMonthDay(trial.endDate)}`

  return (
    <Tile>
      <div onClick={() => !!onSelect ? onSelect(trial) : ''}>
        <TitleRow>Trial Secretary {trial.client ? trial.client.name : ''}</TitleRow>
        <InfoRow>
          <Logo><img style={{height: '60px', width: '60px'}} src={trial.client ? trial.client.iconUrl || theme.logoPath : theme.logoPath} alt=''/></Logo>
          <LeftContainer>
            <MainTitle>{trial.name}</MainTitle>
            <Text>{trial.city}, {trial.state} ({trialDateRange})</Text>
          </LeftContainer>
          <RightContainer>
            <Title>Entry Close Date</Title>
            <Text>{DateHelper.numberToMonthDay(trial.entryCloseDate)}</Text>
          </RightContainer>
        </InfoRow>
        <HerdingEventRows herdingEvents={trial.herdingEvents}/>
      </div>
      <DownloadFilesRow trial={trial} />
    </Tile>
  );
}

TrialTile.propTypes = {
  trial: PropTypes.object.isRequired,
  onSelect: PropTypes.func
}