import React, {useState} from 'react';
import PropTypes from 'prop-types';
import CheckBoxList from './checkBoxList';
import './styles.css';
import styled from '@emotion/styled';
import theme from '../../../lib/common/theme';


const Input = styled.input`
  background-color: ${theme.primaryBackground};
  border: solid 1px ${theme.primaryTextHighlight2};
  color: ${theme.primaryText};
  width: 100%;
  padding-left: ${theme.xsmall};

  &:focus {
    background-color: ${theme.primaryBackground};
    border: solid 1px ${theme.primaryText};
  }
`;

export default function InputDropDown({labelValues, multiValuesAllowed, onChange, values}) {
  const [searchText, setSearchText] = useState('');

  if (values && values.length > 0 && !multiValuesAllowed) {
    return null;
  }

  const onTextChange = ({target:{value}}) => setSearchText(value);
  const onCheck = (value, checked) => {
    if (checked) {
      if (multiValuesAllowed) {
        onChange(values ? values.concat([value]) : [value]);
      } else {
        onChange(value);
      }
    } else {
      if (multiValuesAllowed) {
        onChange(values.filter(val => val !== value));
      } else {
        onChange(undefined);
      }
    }
  }

  let labelValuesBySearchText = labelValues
    .filter(labelValue => searchText === '' || labelValue.label.toLowerCase().indexOf(searchText.toLowerCase()) > -1)
    .filter((_, index) => index < 9);

  return (
    <div>
      <Input type="text" name="Search" placeholder="Search Data" className="input-box" onChange={onTextChange}/>
      <div className="search-result">
        <div className="list-result">
          <CheckBoxList onCheck={onCheck} labelValues={labelValuesBySearchText} values={values} />
        </div>
      </div>
    </div>
  );
}

InputDropDown.propTypes = {
  labelValues: PropTypes.array.isRequired,
  multiValuesAllowed: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  values: PropTypes.array
}

