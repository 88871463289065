import React from 'react';
import PropTypes from 'prop-types';
import Label from './label';
import InputContainer from './inputContainer'
import Input from './input';

export default function IntInput({formName, name, label, onChange, value, maxNumbers}) {
  const id = `${formName}-${name}`;
  const onNumberChange = ({target: {value}}) => {
    let numberValue = value.trim().split('').filter(charItem => !isNaN(parseInt(charItem, 10))).join('');
    numberValue = maxNumbers && numberValue.length > maxNumbers ? numberValue.substring(0, maxNumbers) : numberValue;
    onChange({target: {name, value: numberValue}});
  }

  return (
    <InputContainer>
      <Label htmlFor={id}>{label}</Label>
      <Input id={id} name={name} type='text' value={value} onChange={onNumberChange}/>
    </InputContainer>
  );
}

IntInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  maxNumbers: PropTypes.number
}