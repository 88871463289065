const theme = {
  primaryBackground: '#1e1e1e',
  primaryText: '#9cdcfe',
  primaryTextTitle: '#ce9078',
  primaryTextHighlight1: '#dcdcaa',
  primaryTextHighlight2: '#4ec9b0',

  secondaryBackground: '#3c3c3c',
  secondaryText: '#898989',
  secondaryTextHighlight: '#FFFFFF',

  barrierColor: '#0079ca',

  xsmall: '4px',
  small: '8px',
  medium: '16px',
  large: '24px',
  larger: '36px',
  largest: '48px',

  logoHeight: 80,
  headerHeight: '84px',

  formMinWidth: '400px',
  formMaxWidth: '800px',

  logoPath: '/dog-logo.svg',

  disableOpacity: 0.75
};

export default theme;