import React from 'react';
import styled from '@emotion/styled';
import theme from '../../lib/common/theme';

const Container = styled.div`
  margin-bottom: ${theme.larger}
`;

export default function SpaceContainer({children}) {
  return (
    <Container>{children}</Container>
  );
}
