import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Switch, withRouter } from "react-router-dom";
import { ApolloProvider } from 'react-apollo';
import ApolloClient from 'apollo-client';
import { createHttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { createApolloFetch } from 'apollo-fetch';
import Home from './routes/home';
import Footer from './components/footer';
import Header from './components/header';
import ClientHome from './routes/clientHome'
import Faqs from './routes/faqs'
import ManageClients from './routes/admin/client/manageClients';
import ManageTrials from './routes/admin/trial/manageTrials';
import CreateEditTrial from './routes/admin/trial/createEditTrial';
import UserSession from './components/common/userSession';
import FourOFour from './routes/FourOFour';
import IfAuth from './components/security/ifAuth';
import ManageEvents from './routes/admin/trial/event/manageEvents';
import ManageTrialJudges from './routes/admin/judge/manageTrialJudges';
import styled from '@emotion/styled';
import theme from './lib/common/theme';
import IfAdmin from './components/security/ifAdmin';
import ManageClient from './routes/admin/client/manageClient';

const query = `
  query GetClient($email: String!, $isActive: Boolean) {
    getClient(email: $email, isActive: $isActive) {
      _id
    }
  }
`;

function App() {
  let cookieUser = UserSession.getUser();
  const [currentUser, setUser] = useState(cookieUser);

  const onLogin = loggedInUser => {
    const apolloFetch = createApolloFetch({ uri: process.env.REACT_APP_GRAPHQL_URL });
    const variables = {
      email: loggedInUser.email,
      isActive: true
    };

    apolloFetch({ query, variables }) //all apolloFetch arguments are optional
    .then(({ data: {getClient} }) => {
      if (getClient) {
        loggedInUser.clientId = getClient._id;
      }
  
      UserSession.setUser(loggedInUser);
      setUser(loggedInUser);
    })
    .catch(error => console.warn(error));
  };

  const onLogout = () => {
    UserSession.setUser(null);
    setUser(null);
  }

  const onGhost = (clientId) => {
    let adminUser = UserSession.getUser();
    adminUser.clientId = clientId;
    UserSession.setUser(adminUser);
    setUser(adminUser);
  }

  const clientId = cookieUser && cookieUser.clientId ? cookieUser.clientId : '';
  const email = cookieUser && cookieUser.email ? cookieUser.email : '';

  const AppContainer = styled.div`
    background-color: ${theme.secondaryBackground};
    display: grid;
    grid-template-rows: ${theme.headerHeight} auto ${theme.largest};
    height: 100vh;
    overflow: hidden;
    font-family: Comfortaa, sans-serif
  `;

  const MainContainer = styled.main`
    overflow-y: scroll; 
    padding: ${theme.medium};
  `;

  const FooterWithRouter = withRouter(props => <Footer {...props}/>);

  const authLink = setContext((_, { headers }) => {
    let Authorization = currentUser && currentUser.accessToken ? `${currentUser.clientId}||||${currentUser.accessToken}||||${currentUser.email}` : ''

    return {
      headers: {
        ...headers,
        Authorization
      }
    }
  });

  const httpLink = createHttpLink({
    uri: process.env.REACT_APP_GRAPHQL_URL
  });
  
  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache()
  });

  return (
    <ApolloProvider client={client}>
      <AppContainer>
        <Router>
        <Header onLogin={onLogin} onLogout={onLogout} currentUser={currentUser} />

          <MainContainer>
            <Switch>
              <Route exact path="/" render={(props) => <Home {...props} />} />
              <Route path="/client/home" render={() => <ClientHome currentUser={currentUser} />} />
              <Route exact path="/admin/client" render={() => (
                <IfAdmin email={email}>
                  <ManageClients onGhost={onGhost} />
                </IfAdmin>)} />
              <Route exact path="/admin/client/:id" render={(props) => (
                <IfAuth clientId={clientId}>
                  <ManageClient id={props.match.params.id} history={props.history} />
                </IfAuth>)} />
              <Route path="/faqs" component={Faqs} />
              <Route exact path="/admin/trial" render={(props) => (
                <IfAuth clientId={clientId}>
                  <CreateEditTrial {...props} clientId={clientId} />
                </IfAuth>)} />
              <Route exact path="/admin/trial/:id" render={(props) => (
                <IfAuth clientId={clientId}>
                  <ManageTrials {...props} clientId={clientId} />
                </IfAuth>)} />
              <Route path="/admin/trial/:trialId/event/:eventId" render={(props) => (
                <IfAuth clientId={clientId}>
                  <ManageEvents {...props} clientId={clientId} />
                </IfAuth>)} />
              <Route exact path="/admin/judge" render={(props) => (
                <IfAuth clientId={clientId}>
                  <ManageTrialJudges {...props} />
                </IfAuth>)} />
              <Route component={FourOFour} />
            </Switch>

          </MainContainer>

          <FooterWithRouter />
        </Router>
      </AppContainer>
    </ApolloProvider>
  );
}

export default App;