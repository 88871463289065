import React from 'react';
import PropTypes from 'prop-types';
import HerdingEventRow from './herdingEventRow';
import SubTitle from '../common/subTitle';
import Table from '../common/table';
import TableHeader from '../common/tableHeader';
import SpaceContainer from '../common/spaceContainer';

export default function ListHerdingEvents({onUpdate, onDelete, events}) {
  return (
    <SpaceContainer>
      <SubTitle>Existing Events</SubTitle>
      <Table>
        <thead>
          <tr>
            <TableHeader>Edit</TableHeader>
            <TableHeader>Judge</TableHeader>
            <TableHeader>Event Number</TableHeader>
            <TableHeader>Event Date</TableHeader>
            <TableHeader>Courses</TableHeader>
            <TableHeader>Stocks</TableHeader>
            <TableHeader>Trial Classes</TableHeader>
            <TableHeader>Test Classes</TableHeader>
            <TableHeader>Delete</TableHeader>
          </tr>
        </thead>
        <tbody>
          {events.map(event => (<HerdingEventRow key={event._id} onUpdate={onUpdate} onDelete={onDelete} event={event} />))}
        </tbody>
      </Table>
    </SpaceContainer>
  );
}

ListHerdingEvents.propTypes = {
  onUpdate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  events: PropTypes.array
}