import React from 'react';
import PropTypes from 'prop-types';
import Chips from './chips';
import Label from '../label';
import './styles.css';
import InputDropDown from './inputDropDown';


export default function MultiSelectInput({label, labelValues, multiValuesAllowed, onChange, values}) {
  const onRemove = (value) => {
    if (multiValuesAllowed) {
      onChange(values.filter(val => val !== value));
    } else {
      onChange(undefined);
    }
  };

  return (
    <div>
      <Label>{label}</Label>
      <div className="multiSelect">
        <div className="chip">
          <Chips onRemove={onRemove} labelValues={labelValues} values={values} />
        </div>
        <InputDropDown labelValues={labelValues} values={values} multiValuesAllowed={multiValuesAllowed} onChange={onChange}/>
      </div>
    </div>
  );
}

MultiSelectInput.propTypes = {
  label: PropTypes.string.isRequired,
  labelValues: PropTypes.array.isRequired,
  multiValuesAllowed: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  values: PropTypes.array
}

