import React from 'react';
import { NavLink } from "react-router-dom";
import styled from '@emotion/styled';
import theme from '../lib/common/theme';
import DesktopText from '../components/common/DesktopText';

export default function Footer({location: {pathname}}) {
  const homePath = '/';
  const trialSecretaryPath = '/client/home';
  const faqPath = '/faqs';

  const Footer = styled.footer`
    background-color: ${theme.barrierColor};
    padding: ${theme.xsmall} 0;
    overflow: hidden;
  `; 

  const Nav = styled.nav`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: ${theme.xsmall};
    background-color: ${theme.barrierColor};
    overflow: hidden;
  `;

  const linkStyle = {
    color: theme.secondaryText,
    textDecoration: 'none',
    display: 'block',
    width: '100%',
    textAlign: 'center',
    fontSize: theme.medium,
    lineHeight: theme.largest,
    backgroundColor: theme.primaryBackground
  };

  const activeLinkStyle = {
    color: theme.secondaryTextHighlight,
    fontWeight: '800'
  };

  return (
    <Footer>
      <Nav>
        <NavLink style={pathname === homePath ? {...linkStyle, ...activeLinkStyle} : linkStyle} exact={true} to={homePath}>Home</NavLink>
        <NavLink style={pathname === trialSecretaryPath ? {...linkStyle, ...activeLinkStyle} : linkStyle} to={trialSecretaryPath}><DesktopText>Trial</DesktopText> Secretaries</NavLink>
        <NavLink style={pathname === faqPath ? {...linkStyle, ...activeLinkStyle} : linkStyle} to={faqPath}>FAQ's</NavLink>
      </Nav>
    </Footer>
  );
}
