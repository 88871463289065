import React, {useState} from 'react';
import theme from '../../lib/common/theme';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

export default function Button({to, children, onClick, disable, minWidth, margin, mediaQuery}) {
  const StyledButton = styled.button`
    display: inline-block;
    font-size: ${theme.medium};
    color: ${theme.secondaryTextHighlight};
    text-align: center;
    border-radius: 2px;
    padding: ${theme.small};
    ${margin ? `margin: ${margin};` : ''}
    text-decoration: none;
    font-weight: 400;
    opacity: ${disable ? theme.disableOpacity.toString() : '1'};
    cursor: ${disable ? 'not-allowed' : 'pointer'};
    font-weight: 400;
    min-width: ${minWidth > 0 ? `${minWidth}px` : 'unset'};
    background-color: ${theme.primaryBackground};
    border: solid 1px ${theme.primaryTextHighlight2};
    {&:hover {font-weight: 800; border: solid 1px ${theme.primaryTextHighlight1};}}
    {&:active {background-color: ${theme.secondaryBackground};}}
    ${mediaQuery}
  `;

  return (
    <StyledButton to={to}
      disabled={disable}
      onClick={onClick}>{children}</StyledButton>
  );
}

Button.propTypes = {
  onClick: PropTypes.func,
  disable: PropTypes.bool,
  minWidth: PropTypes.number,
  margin: PropTypes.string,
  mediaQuery: PropTypes.string
}