import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import theme from '../../lib/common/theme';
import DateHelper from '../../lib/common/dateHelper';


export default function HerdingEventRows({ herdingEvents }) {
  if (!herdingEvents) {
    return null;
  }

  const Row = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: ${theme.xsmall};
  `;

  const EventLabel = styled.span`
    color: ${theme.primaryTextTitle};
    font-size: ${theme.medium};
  `;

  const EventDate = styled.span`
    color: ${theme.secondaryTextHighlight};
    font-size: ${theme.medium};
  `;

  const EventData = styled.span`
    color: ${theme.primaryText};
    text-transform: capitalize;
    font-size: ${theme.medium};
  `;

  herdingEvents.sort((a, b) => {
    let eventDateDiff = a.eventDate - b.eventDate;
    if (eventDateDiff !== 0) {
      return eventDateDiff;
    }

    if (a.name < b.name) {
      return -1;
    }

    if (a.name > b.name) {
      return 1;
    }

    return 0;
  });

  return herdingEvents.map((herdingEvent, index) => {
    let courses = herdingEvent.courses || [];
    let testClasses = herdingEvent.testClasses || [];
    let stocks = herdingEvent.stocks || [];
    let trialClasses = herdingEvent.trialClasses || [];

    const HerdingEventRow = styled.div`
      border-bottom: 1px solid ${theme.primaryTextHighlight2};
      margin-bottom: ${theme.small};
      padding-bottom: ${theme.small};
    `;

    return (
      <HerdingEventRow key={herdingEvent._id}>
        <Row>
          <EventLabel>{herdingEvent.trialJudge ? herdingEvent.trialJudge.name : ''}</EventLabel>
          <EventDate>{DateHelper.numberToMonthDay(herdingEvent.eventDate)}</EventDate>
        </Row>
        <Row>
          <EventData>{`${courses.join(', ')} ${stocks.join(', ')}`}</EventData>
          <EventData>{`${trialClasses.length === 3 ? 'All Classes' : trialClasses.join(', ')} (${testClasses.length === 0 ? 'No Test Classes Offered' : testClasses.join(', ')})`}</EventData>
        </Row>
      </HerdingEventRow>
    );
  });

}

HerdingEventRows.propTypes = {
  herdingEvents: PropTypes.array
}