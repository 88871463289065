import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import theme from '../../lib/common/theme';
import Label from './label';

const Container = styled.div`
  display: inline-block;
  margin-bottom: ${theme.small}
`;

const RadioContainer = styled.span`
  display: inline;
`;


export default function RadioInput({name, label, labelValues, value, onChange}) {

  return (
    <Container>
      <Label>{label}</Label>
      <RadioContainer>
        {labelValues.map(labelValue => {
          let id = `${name}-${labelValue.value}`;
          let title = labelValue.isDisabled ? `${labelValue.label} is not available yet.` : '';
          let cursor = labelValue.isDisabled ? 'not-allowed' : 'pointer';
          const RadioItem = styled.li`
            display: inline;
            opacity: ${labelValue.isDisabled ? 0.75 : 1};
            cursor: ${cursor};
          `;

          return (
            <RadioItem key={id} disabled={labelValue.isDisabled}>
              <input id={id} type='radio' 
                style={{cursor}}
                name={name} 
                title={title}
                value={labelValue.value} 
                disabled={labelValue.isDisabled} 
                checked={labelValue.value === value} 
                onChange={onChange} />
              <Label htmlFor={id} title={title} disabled={labelValue.isDisabled} color={theme.primaryText}>{labelValue.label}</Label>
            </RadioItem>
          );
        })}
      </RadioContainer>
    </Container>
  );
}

RadioInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  labelValues: PropTypes.array.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired
}