import React from 'react';
import PropTypes from 'prop-types';
import TableCell from '../common/tableCell';
import Button from '../common/button';
import theme from '../../lib/common/theme';

export default function ListJudgeRow({onUpdate, judge}) {

  return (
    <tr>
      <TableCell>{judge.name}</TableCell>
      <TableCell>{judge.judgeNumber}</TableCell>
      <TableCell>{judge.judgeTypes.join(', ')}</TableCell>
      <TableCell>{judge.isActive ? 'Active' : 'Inactive'}</TableCell>
      <TableCell>
        <Button minWidth={50} margin={theme.xsmall} onClick={() => onUpdate(judge)}>Edit</Button>
      </TableCell>
    </tr>
  );
}

ListJudgeRow.propTypes = {
  onUpdate: PropTypes.func.isRequired,
  judge: PropTypes.object.isRequired
}