import React from 'react';
import styled from '@emotion/styled';
import theme from '../../lib/common/theme';

export default function TableHeader({children}) {
  const TableHeader = styled.th`
    font-size: ${theme.medium};
    color: ${theme.primaryTextTitle};
    text-align: center;
    max-width: 100px;
  `;

  return (
    <TableHeader>{children}</TableHeader>
  );
}