import React from 'react';
import PropTypes from 'prop-types';
import TrialForm from '../../../components/trial/trialForm';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import Loading from '../../../components/common/loading';
import DateHelper from '../../../lib/common/dateHelper';
import Error from '../../../components/common/error';
import Title from '../../../components/common/title';

const GET_TRIAL = gql`
  query GetTrial($trialId: ID!) {
    getTrial(_id: $trialId) {
      _id
      trialType
      name
      startDate
      endDate
      entryCloseDate
      description
      addressLine
      city
      state
      zip
      isPublish
      downloadableFiles {
        linkLabel
        url
      }
      client {
        _id
        name
      }
      herdingEvents {
        _id
        eventNumber 
        eventDate 
        testClasses 
        courses 
        stocks 
        trialClasses 
        trialJudge {
          _id
          name
          judgeNumber
        }
      }
    }
  }
`;

const DEFAULT_TRIAL = { _id: '', trialType: '', name: '', startDate: DateHelper.yyyymmddToday(), endDate: DateHelper.yyyymmddToday(), entryCloseDate: DateHelper.yyyymmddToday(), description: '', addressLine: '', city: '', state: '', zip: '', isPublish: false };

export default function ManageTrials({ match, clientId, history }) {
  const trialId = match.params.id;
  const { data, loading, error } = useQuery(GET_TRIAL, {
    variables: { trialId },
    fetchPolicy: 'no-cache',
    skip: trialId === 'new'
  });
  const onContinue = ({_id, eventId}) => history.replace(`/admin/trial/${_id}/event/${eventId || 'new'}`);
  const onCancel = () => history.push('/admin/trial');

  if (loading) return <Loading />;
  if (error) return <Error />;

  let currentTrial = !!data && !!data.getTrial ? data.getTrial : {...DEFAULT_TRIAL, clientId};
  let numberOfFiles = (currentTrial.downloadableFiles || []).length;
  currentTrial.downloadableFiles = (currentTrial.downloadableFiles || [])
    .concat(new Array(10 - numberOfFiles).fill().map((_) => ({linkLabel: '', url: ''})))
    .map((downloadableFile, index) => ({...downloadableFile, id: index}));

  return (
    <div>
      <Title>{trialId === 'new' ? 'Create New Trial' : `Modify ${currentTrial.name}` }</Title>
      <TrialForm onContinue={onContinue} onCancel={onCancel} currentTrial={currentTrial} />
    </div>
  );
}

ManageTrials.propTypes = {
  match: PropTypes.object.isRequired,
  clientId: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired
}
