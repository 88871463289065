import React from 'react';
import PropTypes from 'prop-types';
import TrialTile from './trialTile';
import SubPageHeader from '../common/subPageHeader';

export default function Trials({ onSelect, trials = [] }) {

  if (trials.length === 0) {
    return (
      <div>
        <SubPageHeader>No Trials Available</SubPageHeader>
      </div>
    );
  }

  return (
    <div>
      {trials.map(trial => <TrialTile key={trial._id} onSelect={onSelect} trial={trial} />)}
    </div>
  );
}

Trials.propTypes = {
  onSelect: PropTypes.func.isRequired,
  trials: PropTypes.array
}