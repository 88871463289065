import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import CheckBoxInputs from '../common/checkBoxInputs';
import IntInput from '../common/intInput';
import DateInput from '../common/dateInput';
import DateHelper from '../../lib/common/dateHelper';
import MultiSelectInput from '../common/multiselect/multiSelectInput';
import FormContainer from '../common/formContainer';
import SubTitle from '../common/subTitle';
import Button from '../common/button';
import ButtonContainer from '../common/buttonContainer';
import SpaceContainer from '../common/spaceContainer';
import FormWithPreviewContainer from '../common/formWithPreviewContainer';
import Title from '../common/title';
import TrialTile from '../trial/trialTile';

const ADD_HERDING_EVENT = gql`
  mutation addHerdingEvent($trialId: String!, $eventNumber: Float!, $eventDate: Int!, $testClasses: [String], $courses: [String], $stocks: [String], $trialClasses: [String], $trialJudgeId: String) {
    addHerdingEvent(trialId: $trialId, eventNumber: $eventNumber, eventDate: $eventDate, testClasses: $testClasses, courses: $courses, stocks: $stocks, trialClasses: $trialClasses, trialJudgeId: $trialJudgeId) {
      _id
    }
  }
`;

const UPDATE_HERDING_EVENT = gql`
  mutation updateHerdingEvent($_id: ID!, $trialId: String!, $eventNumber: Float!, $eventDate: Int!, $testClasses: [String], $courses: [String], $stocks: [String], $trialClasses: [String], $trialJudgeId: String) {
    updateHerdingEvent(_id: $_id, trialId: $trialId, eventNumber: $eventNumber, eventDate: $eventDate, testClasses: $testClasses, courses: $courses, stocks: $stocks, trialClasses: $trialClasses, trialJudgeId: $trialJudgeId) {
      _id
    }
  }
`;

export default function HerdingEventForm({trial, judges, onAdd, currentEvent, defaultEvent})  {
  const [addHerdingEvent] = useMutation(ADD_HERDING_EVENT);
  const [updateHerdingEvent] = useMutation(UPDATE_HERDING_EVENT);
  const [event, setEvent] = useState(currentEvent);
  const [didSetTrialJudgeId, setDidSetTrialJudgeId] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const setForm = ({target: {name, value}}) => setEvent({...event, [name]: value });
  const setEventNumber = ({target: {value}}) => setEvent({...event, eventNumber: Number(value)});
  const setEventDate = (date) => setEvent({...event, eventDate: DateHelper.DateToyyyymmdd(date)});
  const setJudgeId = (trialJudgeId) => {
    setDidSetTrialJudgeId(true);
    setEvent({...event, trialJudgeId});
  };

  const possibleTestClasses = [{label: 'Boundary', value: 'boundary'}, {label: 'Pre-Trial', value: 'preTrial'}, {label: 'Herding', value: 'herding'}, {label: 'Instinct Test', value: 'instinct test'}, {label: 'Farm Dog', value: 'farm dog'}];
  const possibleCourses = [{label: 'A', value: 'a'}, {label: 'B', value: 'b'}, {label: 'C', value: 'c'}, {label: 'D', value: 'd'}];
  const possibleStocks = [{label: 'Cattle', value: 'cattle'}, {label: 'Ducks', value: 'ducks'}, {label: 'Sheep', value: 'sheep'}];
  const possibleTrialClasses = [{label: 'Started', value: 'started'}, {label: 'Intermediate', value: 'intermediate'}, {label: 'Advance', value: 'advance'}];

  let trialJudgeId = didSetTrialJudgeId ? event.trialJudgeId : event.trialJudgeId ? event.trialJudgeId : event.trialJudge && event.trialJudge._id ? event.trialJudge._id : undefined;
  let trialJudgeIds = trialJudgeId ? [trialJudgeId] : undefined;

  const onClick = () => {
    if (event._id) {
      updateHerdingEvent({ variables: { ...event, trialId: trial._id, trialJudgeId}})
        .then(({data: {updateHerdingEvent}}) => !!updateHerdingEvent ? onAdd() : setErrorMessage(`Could not update the existing event with id: ${currentEvent._id}`))
        .catch(error => setErrorMessage(error));
    } else {
      addHerdingEvent({ variables: {...event, trialJudgeId}})
        .then(({data: {addHerdingEvent}}) => !!addHerdingEvent ? onAdd() : setErrorMessage('Could not add a new event'))
        .catch(error => setErrorMessage(error));
    }

    setEvent(defaultEvent);
    setDidSetTrialJudgeId(false);
  };

  useEffect(() => {
    setEvent(currentEvent);
  }, [currentEvent]);

  return (
    <FormWithPreviewContainer justifyContent='space-around' flexWrap='wrap'>
      <FormContainer>
        <SubTitle>{event._id ? `Modify ${event.eventNumber}` : 'Add Event' }</SubTitle>

        <MultiSelectInput label='Herding Judge' multiValuesAllowed={false} labelValues={judges.map(judge => ({label: `${judge.judgeNumber} - ${judge.name}`, value: judge._id}))} onChange={setJudgeId} values={trialJudgeIds} />
        <IntInput formName='event' name='eventNumber' label='Event Number' value={event.eventNumber.toString()} onChange={setEventNumber} maxNumbers={10} />
        <DateInput formName='event' label='Event Date' value={Number(event.eventDate)} onChange={setEventDate} />
        <CheckBoxInputs formName='event' name='testClasses' label='Test Classes' labelValues={possibleTestClasses} values={event.testClasses} onChange={setForm} />
        <CheckBoxInputs formName='event' name='courses' label='Course' labelValues={possibleCourses} values={event.courses} onChange={setForm} />
        <CheckBoxInputs formName='event' name='stocks' label='Stock' labelValues={possibleStocks} values={event.stocks} onChange={setForm} />
        <CheckBoxInputs formName='event' name='trialClasses' label='Trial Classes' labelValues={possibleTrialClasses} values={event.trialClasses} onChange={setForm} /> 
        <ButtonContainer>
          <Button minWidth={160} onClick={onClick}>{event._id ? `Save ${event.eventNumber} Event` : 'Create New Event' }</Button>
        </ButtonContainer>
        
        <div>
          {errorMessage}
        </div>
      </FormContainer>

      <SpaceContainer>
        <Title>Preview Trial Tile</Title>
        <TrialTile trial={trial} />
      </SpaceContainer>

    </FormWithPreviewContainer>
  );
}

HerdingEventForm.propTypes = {
  trial: PropTypes.object.isRequired,
  judges: PropTypes.array.isRequired,
  onAdd: PropTypes.func.isRequired,
  currentEvent: PropTypes.object.isRequired,
  defaultEvent: PropTypes.object.isRequired
}